var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","align":"right"}},[(_vm.devices.length > 0)?_c('v-tabs',{attrs:{"right":"","dark":_vm.$store.getters.getColorPalette().isDark,"background-color":"transparent lighten-5","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":"#list"}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().list)+" "),_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('v-tab',{attrs:{"href":"#single"}},[_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().single)+" "),_c('v-icon',[_vm._v("mdi-border-all-variant")])],1)],1):_vm._e()],1)],1),(_vm.tab == 'list')?_c('v-row',[_c('v-col',[_c('v-expansion-panels',{attrs:{"multiple":"","hover":"","tile":""}},[_vm._l((_vm.devices),function(device){return _c('v-expansion-panel',{key:device.device_id,style:({
              border:
                '1px solid ' + _vm.$store.getters.getColorPalette().cardBorderColor,
              'background-color':
                _vm.$store.getters.getColorPalette().background2ColorCode,
            }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-expansion-panel-header',[_c('h3',{attrs:{"color":_vm.$store.getters.getColorPalette().accentCode}},[_vm._v(" "+_vm._s(device.name)+" ")])]),_c('v-expansion-panel-content',[_c('Device',{attrs:{"device":device},on:{"del-device":function($event){return _vm.deleteDevice(device)}}})],1)],1)}),_vm._l((_vm.s7Devices),function(device){return _c('v-expansion-panel',{key:device.device_id,style:({
              border:
                '1px solid ' + _vm.$store.getters.getColorPalette().cardBorderColor,
              'background-color':
                _vm.$store.getters.getColorPalette().background2ColorCode,
            }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-expansion-panel-header',[_c('h3',[_vm._v(_vm._s(device.name))])]),_c('v-expansion-panel-content',[_c('S7Device',{attrs:{"device":device}})],1)],1)}),_vm._l((_vm.vDevices),function(device){return _c('v-expansion-panel',{key:device.device_id,style:({
              border:
                '1px solid ' + _vm.$store.getters.getColorPalette().cardBorderColor,
              'background-color':
                _vm.$store.getters.getColorPalette().background2ColorCode,
            }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-expansion-panel-header',[_c('h3',[_vm._v(_vm._s(device.name))])]),_c('v-expansion-panel-content',[_c('VDevice',{attrs:{"vdevice":device}})],1)],1)}),_vm._l((_vm.mqttDevices),function(device){return _c('v-expansion-panel',{key:device.device_id,style:({
              border:
                '1px solid ' + _vm.$store.getters.getColorPalette().cardBorderColor,
              'background-color':
                _vm.$store.getters.getColorPalette().background2ColorCode,
            }),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-expansion-panel-header',[_c('h3',[_vm._v(_vm._s(device.name))])]),_c('v-expansion-panel-content',[_c('MQTTDevice',{attrs:{"device":device},on:{"del-device":function($event){return _vm.deleteDevice(device)}}})],1)],1)})],2)],1)],1):_vm._e(),(_vm.tab == 'single')?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.deviceList,"item-text":"label","item-value":"value","label":_vm.$store.getters.getTextMap().select_device,"multiple":"","required":"","clearable":"","small-chips":"","color":_vm.$store.getters.getColorPalette().inputtextColor,"menu-props":{dark: _vm.$store.getters.getColorPalette().isDark}},model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}})],1)],1),_vm._l((_vm.selectedDevices),function(device){return _c('v-row',{key:device.device_id,attrs:{"color":"purple"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":_vm.$store.getters.getColorPalette(). background2ColorCode}},[_c('v-card-title',[_vm._v(_vm._s(device.name))]),_c('Device',{attrs:{"device":device}})],1)],1)],1)})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<div class="mt-5 ml-6" >
<!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper"> Your devices</h1> -->
<DeviceNumberPanel/>
<v-container fluid>
    
    <CreateWorkspaceModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" align="right" />
    <v-row no-gutters>
        <v-col cols="12">
            <WorkspaceList/>
            </v-col></v-row>
            <!-- <v-row no-gutters v-if="$store.state.user && $store.state.user.organisation_id==='26c7ea9e-8d15-4022-bd9e-e4166a6078e5' && $store.getters.getAccessByLevels(['engineeraccess','betaaccess']) ">
                <v-col cols="12">
                    <ParamDummyList/>
                </v-col>
            </v-row> -->
</v-container>
</div>
</template>

<script>
import DeviceNumberPanel from '@/components/panels/DeviceNumberPanel'
import WorkspaceList from '@/components/lists/WorkspaceList'
import CreateWorkspaceModal from '@/components/modals/CreateWorkspaceModal'
// import ParamDummyList from '@/components/lists/ParamDummyList'
export default {
    name:'Devices',
    components:{
        WorkspaceList,
        CreateWorkspaceModal,
        DeviceNumberPanel,
        // ParamDummyList
    },
    mounted() {
        document.title='Devices'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    }
}
</script>
<style scoped>
.container{
    margin-top: -2%;
    margin-left: -2%;
   
}

</style>
<template>
  <v-container
    fluid
    :style="{
      background: $store.getters.getColorPalette().backgroundColorCode,
    }"
  >
    <InfoAlert
      :showDismissibleAlert="showDismissibleAlert"
      v-on:close="showDismissibleAlert = false"
      :info="info"
    />

    <v-card
      flat
      :background-color="$store.getters.getColorPalette().backgroundColorCode"
      :dark="$store.getters.getColorPalette().isDark"
    >
      <v-card-title>
      {{ $store.getters.getTextMap().create_parameter_from_template}}
        <v-spacer></v-spacer>
        <v-btn
          :elevation="0"
          :color="$store.getters.getColorPalette().submitbtnColor"
          @click="onReset"
        >
          <v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
        <v-form ref="paramForm" v-model="valid">
          <v-row>
            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-select
                :label="$store.getters.getTextMap().make"
                clearable
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
                v-model="form.make"
                :items="oemData"
                item-text="label"
                item-value="value"
                required
                :rules="selectRules"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              >
              </v-select>
            </v-col>

            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-select
                :label="$store.getters.getTextMap().model"
                clearable
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
                v-model="form.model"
                :items="mdevicesData"
                item-text="label"
                item-value="value"
                required
                :rules="selectRules"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
              >
              </v-select>
            </v-col>

            <v-col>
              <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
              ></v-skeleton-loader>
              <v-autocomplete
                :label="$store.getters.getTextMap().parameters"
                clearable
                :dark="$store.getters.getColorPalette().isDark"
                :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
                :items="paramsTemplateData"
                v-model="form.parameter"
                item-text="label"
                item-value="value"
                required
                :rules="selectRules"
                :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
                multiple
              >
             </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-subtitle>

      <v-row>
        <v-col cols="12">
          <div :v-model="form.parameter" :style="{width: '210px'}"  class="px-3">
              <v-checkbox
              :dark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().inputtextColor"
              v-if="paramsTemplateData.length !== 0"
              :v-model="selectAll"
              :label="$store.getters.getTextMap().select_all_parameters"
              @change="selectAllParams"
            ></v-checkbox>
          </div>
          </v-col>

        <v-col cols=12>
          <div class="px-4">
            <p>{{ $store.getters.getTextMap().selected_parameters_count }}: {{ form.parameter.length }}</p>
          </div>

          <v-chip
            v-for="selectedParameter in form.parameter"
            :key="selectedParameter.parameter_name"
            close
            @click:close="removeSelectedParameter(selectedParameter)"
          >
            {{ selectedParameter.parameter_name }}
          </v-chip>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-checkbox
          :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
          v-model="form.engineerAccessOnly"
          :label="$store.getters.getTextMap().restricted_user_access"
        >
        </v-checkbox>

        <v-spacer></v-spacer>
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>

        <v-btn
          v-if="!loading"
          :color="$store.getters.getColorPalette().submitbtnColor"
          text
          @click="OnSubmit"
        >
          {{$store.getters.getTextMap().create}}
        </v-btn>
        <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
          text
          @click="onReset"
        >
        {{ $store.getters.getTextMap().cancel }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-data-table
      :headers="headers"
      item-key="timestamp"
      :loading="loading"
      :items="parametersData"
      item-class="font-weight-black text-center"
      :dark="$store.getters.getColorPalette().isDark"
    >
    </v-data-table>
  </v-container>
</template>

<script>
import InfoAlert from "@/components/InfoAlert.vue";
import axios from "axios";
export default {
  name: "CreateParameterFromTemplate",
  components: {
    InfoAlert,
  },
  props: ["device"],
  data() {
    return {
      showDismissibleAlert: false,
      info: "",
      valid: false,
      loading: false,
      form: {
        make: null,
        model: null,
        parameter: [],
      },
      selectRules: [(v) => v != null || "Required"],
      headers: [
        {
          text: "Make",
          align: "start",
          filterable: false,
          value: "make",
        },
        {
          text: "Model",
          value: "model",
        },
        {
          text: "Parameter",
          value: "parameter",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      oemData: [],
      mdevicesData: [],
      paramsTemplateData: [],
      parametersData: [],
      selectAll: false,
    };
  },
  mounted() {
    this.getOemsData();
  },
  methods: {
    getOemsData() {
      this.oemData = [];
      let endPoint = "/oems";
      axios
        .get(this.$store.state.api + endPoint, {
          headers: {
            Authorization: "Bearer " + this.$store.state.jwt,
          },
        })
        .then((response) => {
          if (
            response.data.status == "success" &&
            response.data.data.length > 0
          ) {
            for (let i of response.data.data) {
              this.oemData.push({
                label: i.name,
                value: i.oem_id,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMdevicesData(oem) {
      this.mdevicesData = [];
      axios
        .post(
          this.$store.state.api + "/getModelsByOEM",
          { oem_id: oem },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          let status = response.data.status;
          if (status == response.data.status && response.data.data.length > 0) {
            const data = response.data.data;
            for (let j of data) {
              this.mdevicesData.push({
                label: j.model,
                value: j.template_id,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAllParamsTemplateData(template_id) {
      this.paramsTemplateData = [];
      axios
        .post(
          this.$store.state.api + "getParamsByTemplate",
          { template_id: template_id },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt,
            },
          }
        )
        .then((response) => {
          if (
            response.data.status == "success" &&
            response.data.data.length > 0
          ) {
            const data = response.data.data;

            for (let k of data) {
              let temp = {};
              temp["label"] = k["parameter_name"];
              temp["value"] = k;
              this.paramsTemplateData.push(temp);
            }
          }
        });
    },

    removeSelectedParameter(parameter) {
      const index = this.form.parameter.indexOf(parameter);
      if (index !== -1) {
        this.form.parameter.splice(index, 1);
      }
    },
    selectAllParams() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        // Select all parameters
        this.form.parameter = this.paramsTemplateData.map(
          (param) => param.value
        );
      } else {
        // Deselect all
        this.form.parameter = [];
      }
    },

    OnSubmit() {
      this.$refs.paramForm.validate();
      if (this.valid) {
        this.loading = true;
        let proceed = true;
        this.parametersData = [];
        for (const i of this.form.parameter) {
          i["name"] = i["parameter_name"];
          i["device_id"] = this.device.device_id;
          i["unit_id"] = this.device.unit_id;
          i["offset"] = i["ofst"];

          const parameterData = {
            make: this.form.make,
            model: this.form.model,
            parameter: i.parameter_name,
            status: "Creating",
          };

          this.parametersData.push(parameterData);

          if (proceed) {
            axios
              .post(this.$store.state.api + "addParameter", i, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.jwt,
                },
              })
              .then((response) => {
                proceed = true;
                if (response.data.status == "success") {
                  parameterData.status = "Created";
                  this.$refs.paramForm.reset();
                  this.$store.dispatch("refreshParameters");
                } else {
                  parameterData.status = "Failed - " + response.data.msg;
                }
                this.loading = false;
              })
              .catch((err) => {
                proceed = false;
                this.info = err;
                this.showDismissibleAlert = true;
                parameterData.status = "Error";
              });
          } else {
            this.loading = false;
            this.info = "Error";
            this.showDismissibleAlert = true;
          }
        }
      }
    },

    onReset() {
      this.$refs.paramForm.reset();
      this.loading = false;
      this.$emit("close");
      this.parametersData = [];
    },
  },

  watch: {
    "form.make": function (newValue) {
      console.log(newValue);
      this.form.model = null;
      if (newValue) {
        this.getMdevicesData(newValue);
      } else {
        this.mdevicesData = [];
      }
    },

    "form.model": function (newValue) {
      this.form.parameter=[]
      this.selectAll=false
      if (newValue) {
        this.getAllParamsTemplateData(newValue);
      } else {
        this.paramsTemplateData = [];
      }
    },
  },
};
</script>

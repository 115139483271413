<template>
<div>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
    <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-form
    ref="writeForm"
    v-model="valid" >
        <v-card-title class="headline">
          {{$store.getters.getTextMap().set_value}}

        </v-card-title>
        <v-card-text>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            v-if="!loading"
      v-model="value"
      :counter="15"
      :rules="floatRules"
      :label="$store.getters.getTextMap().value"
      required
    ></v-text-field>
                    </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="dialog = false"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
          <v-btn
           :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="write"
          >
          {{$store.getters.getTextMap().write}}
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
<v-btn
      color="blue"
      @contextmenu="show"
      
 :dark="$store.getters.getColorPalette().isDark"
      @click="dialog = !dialog"
    >
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
     <span v-if="!loading"> Set {{writeParam.name ?writeParam.name : writeParam.parameter}}</span> <v-icon
        right
  :dark="$store.getters.getColorPalette().isDark"
      >mdi-pen
      </v-icon>
    </v-btn>
 <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      @click="dialog=true"
    >
      <v-list>
        <v-list-item @click="showDialog = true">
            <v-list-item-title><strong>Delete</strong></v-list-item-title>
            <v-list-item-icon>
               <DeleteConfirmation  v-on:confirm="deleteWriteParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">                 
            <v-icon small>mdi-trash-can</v-icon>
              </DeleteConfirmation>
          </v-list-item-icon>
          
          
        </v-list-item>
      </v-list>
    </v-menu>
</div>
    
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
export default {
    name:'WriteParam',
    props:['writeParam'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    data: () => ({
       showDialog:false,
      showMenu: false,
      valid:false,
      dialog:false,
      loading:false,
      x: 0,
      y: 0,
      info:'',
      showDismissibleAlert:false,
      value:null,
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>=0)|| 'It should be a positive number',
          v => (v && v.length <= 15 && v.length > 0) || 'Name must be less than 16 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>=0 )|| 'It should be more than zero',
          v => (v && v.length <= 15 && v.length >= 0) || 'It must be less than 16 digits',
      ],
    }),
    methods: {
      show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      deleteWriteParam(){
          console.log("delete")
          axios.post(this.$store.state.api+'deleteWriteParam',this.writeParam,{headers: {
              Authorization: 'Bearer '+this.$store.state.jwt
          }}).then(response=>{
              if(response.data.status==='success'){
        this.$store.dispatch('refreshWriteParams')
        
        //this.onReset()
            //this.dialog=false
            //this.$emit('close')
            this.loading=false
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }

          }).catch(err=>{
              this.loading=false
      this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
          })
      },
      write(){
          this.$refs.writeForm.validate()
            if(this.valid){
                this.loading=true
                let d={
                    parameter:this.writeParam.parameter,
                    value:this.value
                }
                axios.post(this.$store.state.api+'writeParam',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshData')
        
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
      console.log(err)
      });
      
            }
      },
      onReset(){
            this.$refs.writeForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },

}
</script>
<template>
    <v-container fluid  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card flat :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
      <v-card-title>{{$store.getters.getTextMap().s7_device}}</v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
    <v-form
            ref="deviceForm"
            v-model="valid"          
        >
        <v-row>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="20"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.ip"
      :counter="16"
      :rules="[rules.required,rules.ip]"
      :label="$store.getters.getTextMap().IPAddress"
      required
    ></v-text-field>
                    </v-col>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.port"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().port"
      required
    ></v-text-field>
                    </v-col>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.rack"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().rack"
      required
    ></v-text-field>
                    </v-col>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.slot"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().slot"
      required
    ></v-text-field>
                    </v-col>

                <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                     
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.msg"
                    :counter="45"
                    
                    :label="$store.getters.getTextMap().description"
                    required>
                    </v-text-field>
                </v-col>

                    
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                     
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.make"
                    :counter="45"
                    
                    :label="$store.getters.getTextMap().make"
                    required>
                    </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                     
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.model"
                    :counter="45"
                    
                    :label="$store.getters.getTextMap().model"
                    required>
                    </v-text-field>
          </v-col>
        </v-row>
        
    </v-form>
      </v-card-subtitle>
      <v-card-actions>
         
         
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{device? $store.getters.getTextMap().update:$store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
             :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateS7Device',
    props:['workspace','device'],
    components:{
      InfoAlert
    },
    mounted(){
      if(this.device && this.device.device_id){
        this.form={...this.device}
        this.api='updateS7Device'
      }else{
        this.form={workspace_id:this.workspace.workspace_id}
        this.api='createS7Device'
      }
    },
    data(){
        return {
            valid:false,
            loading:false,
            info:'',
            api:'createS7Device',
            showDismissibleAlert:false,
            form:{
                name:null,
                slot:null,
                ip:null,
                port:null,
                rack:null,
            },
            rules:FormRules.rules,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            floatRules:[
                v => !!v || 'Required',
                v => (v && !isNaN(Number(v))) || 'It should be a number',
                v => (v && v>0 )|| 'It should be more than zero',
                v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
            ],
            numberRules:FormRules.numberRules,
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
        }
    },
    computed:{
       
    },
    methods:{
      onSubmit(){
            this.$refs.deviceForm.validate()
            if(this.valid){
              this.loading=true
            let d=Object.assign({}, this.form)
            if(this.device && this.device.device_id){
              d.device_id=this.device.device_id
            }
            

            axios.post(this.$store.state.api+this.api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshS7Devices')
        this.onReset()
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
            }
        },
        onReset(){
          if(!(this.device && this.device.device_id)){
            this.$refs.deviceForm.reset()
          }
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    }

}
</script>
<style scoped>

/* .v-sheet.v-card {
   background-color: #263238;
} */
</style>
<template>
   <div>
                <v-btn
                :background-color="$store.getters.getColorPalette().background1ColorCode"
                :color="$store.getters.getColorPalette().accent"
                small 
                text
                :dark="$store.getters.getColorPalette().isDark"
                outlined
                @click="dialog = !dialog"
                >
                {{$store.getters.getTextMap().create_parameter_from_template}} 
                <v-icon
                right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small
                >mdi-chart-tree</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" fullscreen>
                    <CreateParameterFromTemplate :device="device" v-on:close="closeDialog" />
                </v-dialog>
    </div>
           
</template>


<script>
import CreateParameterFromTemplate from '@/components/crud_components/templating/CreateParameterFromTemplate.vue';
export default
{
    name:'CreateParameterFromTemplateSheet',
    components:{
        CreateParameterFromTemplate
    },
    props:["device"],
    data(){
        return{
            dialog:false
        }
    },
    methods:{
        closeDialog(){
            this.dialog = false
        }
    }
}
</script>
<template>
    <v-container  fluid>
        <v-expansion-panels
      v-model="panels"
      multiple
      hover
      tile
     :dark="$store.getters.getColorPalette().isDark"
     :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}"
    >
      <v-expansion-panel 
      
      :dark="$store.getters.getColorPalette().isDark"
      expand
        v-for="workspace in workspaces"
        :key="workspace.workspace_id"
       
      >
        <v-expansion-panel-header :color="$store.getters.getColorPalette().expansionPanelHeaderColorName" ><h3 class="">{{workspace?workspace.name:'-'}}</h3> </v-expansion-panel-header>
        <v-expansion-panel-content >
          <Workspace :workspace="workspace" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </v-container>
</template>

<script>
import Workspace from '@/components/items/Workspace'
export default {
    name:'WorkspaceList',
    data(){
        return {
      panels:[]
    }
    },
    components:{
        Workspace
    },
    methods:{
        refresh(){
            // this.$store.dispatch('refreshNodeStates');
        },
        deleteWorkspace(workspace){

          if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
               this.$store.dispatch('deleteWorkspace',Workspace)
           }else{
               this.showDismissibleAlert=true
               this.info="You do not have access for this"
               console.log(workspace)
           }

        },

    },
    computed:{
        workspaces(){
            return this.$store.state.workspaces;
        }
    }
}
</script>
<style scoped>
.v-expansion-panels{
    margin-top: 3%;
    margin-left: 1%;
   
}
</style>
<template>

    <v-dialog
      v-model="dialog"      
      
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn  v-bind="attrs"
          v-on="on" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  >{{$store.getters.getTextMap().edit_device}}
        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
      </v-btn>
      </template>
     
      <v-card :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">

        <v-card-title class="headline">
          {{$store.getters.getTextMap().edit_device}}
        </v-card-title>
        <v-card-text>
            <CreateS7Device :device="device" v-on:close="close" />
        </v-card-text>
        
      </v-card>
    </v-dialog>
      
</template>

<script>
// import axios from 'axios';
// import InfoAlert from '@/components/InfoAlert'
import CreateS7Device from '@/components/crud_components/engineering/CreateS7Device'

  export default {
      name:'EditS7DeviceModal',
      props:['device'],
      components:{
//  InfoAlert,
 CreateS7Device
      },
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
        }
            
    },
     mounted(){
        
       
    },
     computed:{
      
         
    },
    methods: {
        close(){
            this.dialog=false
            this.$emit('close')
        },

       
      onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            //this.$refs.groupForm.reset()
            this.dialog=false
            this.loading=false
            this.info=''
        }
   
  }
  }
</script>
<template>
  <div class="text-center">
    <v-btn
    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
      @click="dialog = !dialog"
    >
    {{$store.getters.getTextMap().write_register}}
 <v-icon
      right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small
      >mdi-grease-pencil
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <WriteDeviceRegister v-on:close="dialog=false" :device="device" />
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import WriteDeviceRegister from '@/components/WriteDeviceRegister'
export default {
    name:'WriteRegisterBottomSheet',
    props:['device'],
    components:{
      WriteDeviceRegister  
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>
<style>

.devc{
 border-radius:20px;
 margin-left: -27px;
 margin-right: 25px
}
  

</style>
<template>
    <v-container fluid  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card flat :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
      <v-card-title>{{param?$store.getters.getTextMap().edit:$store.getters.getTextMap().create}} {{$store.getters.getTextMap().derived_parameter}}
 </v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
    <v-form
            ref="paramForm"
            v-model="valid"          
        >
        <v-row>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="20"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
            <v-col>
                <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                 :dark="$store.getters.getColorPalette().isDark" 
                 :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.parameter"
          :items="paramOptions"
          :label="$store.getters.getTextMap().device_parameter"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="selectRules"
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                v-if="loading"
                class="mx-auto"
                type="text"
                ></v-skeleton-loader>
                <v-select
                 :dark="$store.getters.getColorPalette().isDark" 
                :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
                v-model="form.multiplier_type"
                :items="param_types"
                :label="$store.getters.getTextMap().multiplier_types"
                outlined
                item-text="label"
                item-value="value"
                required
                :rules="selectRules"
               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                ></v-select>
            </v-col>
            <v-col v-if="form.multiplier_type=='text'">
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                     :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.multiplier"
                    :counter="13"
                    :rules="numberRules"
                    :label="$store.getters.getTextMap().multiplier"
                    required>
                    </v-text-field>
                </v-col>
                <v-col v-else>
                    <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                    ></v-skeleton-loader>
                    <v-select
                    :dark="$store.getters.getColorPalette().isDark" 
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.multiplier"
                    :items="multiplierOptions"
                    :label="$store.getters.getTextMap().multiplier"
                    outlined
                    item-text="label"
                    item-value="value"
                    required
                    :rules="selectRules"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    ></v-select>
                </v-col>
                <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                     
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.unit"
                    :counter="8"
                    
                    :label="$store.getters.getTextMap().unit"
                    required>
                    </v-text-field>
                </v-col>

                    
        </v-row>
        
    </v-form>
      </v-card-subtitle>
      <v-card-actions>
          <v-checkbox
          :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.engineerAccessOnly"
      :label="$store.getters.getTextMap().restricted_user_access"
    ></v-checkbox>
         
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{param? $store.getters.getTextMap().update:$store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
             :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateDerivedParam',
    props:['device','param'],
    components:{
      InfoAlert
    },
    mounted(){
      if(this.param && this.param.param_id){
        this.form={...this.param}
        this.api='updateDerivedParam'
      }else{
        this.api='createDerivedParameter'
      }
    },
    data(){
        return {
            valid:false,
            loading:false,
            info:'',
            api:'createDerivedParameter',
            showDismissibleAlert:false,
            form:{
                name:null,
                parameter:null,
                multiplier:null,
                multiplier_type:null,
                unit:null,
            },
            param_types:[
                {label:'Text', value:'text'},
                {label: 'Calculated', value:'calculated'}
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            floatRules:[
                v => !!v || 'Required',
                v => (v && !isNaN(Number(v))) || 'It should be a number',
                v => (v && v>0 )|| 'It should be more than zero',
                v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
            ],
            numberRules:FormRules.numberRules,
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
        }
    },
    computed:{
        paramOptions(){
             
            let op=[]
          
          if(this.device && this.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
                  
              
          }
          }else if(this.param && this.param.param_id && this.param.device_id){
            let g=this.$store.getters.getParametersByDeviceID(this.param.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
              }
          }
          return op
        },
        multiplierOptions(){
            let op=[]
            if(this.form.multiplier_type=='calculated'){
                let g=this.$store.state.calculatedParams
                for(let i of g){
                  
                  if(i && i.name ){
                    
                        op.push({value:i.param_id, label: i.name })
                    }else{
                        op.push({value:i.param_id, label: i.param_id })
                        }
                }
            }
            return op
        }
    },
    methods:{
      onSubmit(){
            this.$refs.paramForm.validate()
            if(this.valid){
              this.loading=true
            let d=Object.assign({}, this.form)
            if(this.param && this.param.param_id){
              d.param_id=this.param.param_id
            }else{
              d.device_id=this.device.device_id
            }
            

            axios.post(this.$store.state.api+this.api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshDerivedParams')
        this.onReset()
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
            }
        },
        onReset(){
            this.$refs.paramForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    }

}
</script>
<style scoped>
.v-card__title{
  color:white;
}
/* .v-sheet.v-card {
   background-color: #263238;
} */
</style>
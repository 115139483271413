<template>

      <v-container fluid>
    <v-card   :dark="$store.getters.getColorPalette().isDark" class="px-0 py-0 mx-0 my-0 text-capitalize " :color="'light-green'"  @click="dialog = true" >
      <v-dialog
      v-model="showDismissibleAlert"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Ooops!!</v-card-title>

        <v-card-text>
         {{info}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          

          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="showDismissibleAlert = false"
          >
          {{$store.getters.getTextMap().ok}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
        
            <v-card-title class="px-1 py-0 my-0 mx-0">
              <v-row>
                <v-col align="right">
              <span class="text-subtitle-2">{{name}} </span>
                </v-col>
              </v-row>
              </v-card-title>
            <v-divider></v-divider>
<v-card-subtitle class="px-1 py-0 my-0 mx-0">
<strong class="text-h6 white--text">{{latestVal}}</strong> {{derivedParam.unit}}
</v-card-subtitle>
<v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :dark="$store.getters.getColorPalette().isDark">
    <v-toolbar
           :dark="$store.getters.getColorPalette().isDark"
          color="primary"
        >
          <v-btn
            icon
            :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{name}}  {{$store.getters.getTextMap().details}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
            <v-row >
              <v-col>
                <a hidden ref="csvDownload"></a>
          <v-btn
            color="success"
            @click="downloadPDF()"
          >
            Download latest usage table
          </v-btn>
            </v-col>
            <v-spacer></v-spacer>
                <v-col align="right">
                    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])"
        @click="editShow=!editShow"
      >
        mdi-pencil
      </v-icon>
     
   
   <DeleteConfirmation v-on:confirm="deleteDerivedParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">   
     <v-icon
        small
      :color="$store.getters.getColorPalette().deletebtnColor"
      >
        mdi-delete
      </v-icon>
      </DeleteConfirmation>
      </span>
                </v-col>
          
        </v-row>

        <v-divider></v-divider>
        <v-row v-if="editShow">
          <v-col><CreateDerivedParam :param="derivedParam" v-on:close="editShow=false"/> </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col>
       <LineGraphByTimeStamp :label="name" :graphData="streamData" dataKey="data" :unit="derivedParam.unit" />
            </v-col>
        </v-row>
        </v-container>
        
    </v-card>
        </v-dialog>
        
        
    </v-card>
      </v-container>

</template>

<script>
import LineGraphByTimeStamp from '@/components/graphs/LineGraphByTimeStamp'
import CreateDerivedParam from '@/components/crud_components/CreateDerivedParam'
//import EditComputedParam from '@/components/crud_components/EditComputedParam'
// import jsPDF from 'jspdf' 
import {io} from 'socket.io-client'
import moment from 'moment'
// import 'jspdf-autotable'
// import { applyPlugin } from 'jspdf-autotable'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
// applyPlugin(jsPDF)

export default {
    name:'DerivedParam',
    props:['derivedParam'],
    components:{
      LineGraphByTimeStamp,
      CreateDerivedParam,
      //BarGraphByDate,
      DeleteConfirmation
    },
    data(){
        return {
          showDialog:false,
            overlay:false,
            dialog:false,
            editShow:false,
            info:'Error',
            showDismissibleAlert:false,
            isActive:false,
            stream:null,
            streamData:[]

        }
    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
    mounted(){
      //this.streamData=this.currentData
      this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.log(data)
      
    })
    this.stream.on('ack',data=>{
      console.log(data)
      
    })
    this.stream.on('param',data=>{
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      this.val=data.data
      this.streamData.push(data)
      //console.log(data)
      if(this.streamData.length>50){
        this.streamData.shift()
      }
    })
    this.stream.emit('getParam',this.derivedParam.param_id)
    },
    computed: {
        name(){
            return this.derivedParam.name ? this.derivedParam.name : this.derivedParam.unit
        },
        currentData(){
            //console.log(this.parameter.parameter)
            return this.$store.getters.getCurrentDerivedParameterDataById(this.derivedParam.param_id)
        },
        latestVal(){
          if( this.streamData.length>0){
                //console.log("current data size accurate")
                /*if(this.timeDelta>1.5){
                  return '-'
                }*/
                
            return Math.round(Number(this.streamData[this.streamData.length-1]['data'])*100)/100

            }
            return '-'
        },
        timeDelta(){
            if( this.streamData.length>0){
                //console.log("current data size accurate")
            return  (Number(moment().format("X")) - Number(this.streamData[this.streamData.length-1]['timestamp']))/120
            }
            return 2
        },
        
    },
    methods: {
        downloadPDF(){
          //let pdf = new jsPDF('landscape','pt',[512,600]);
          //  let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Date',this.name]
            let body=[]
            for (let i of this.currentData){
                let temp=[]
                temp.push(moment(i['date'],'YYYYMMDD').format('MMMM Do YYYY'))
                temp.push((Math.round(Number(i['total'])*100)/100))
                
                body.push(temp)
            }
//             pdf.autoTable({
//   head: [header],
//   body: body,
// })
//             pdf.save(this.name+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.name+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();
        },
        deleteDerivedParam(){                    
          if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
               //this.$store.dispatch('deleteDerivedParam',this.derivedParam)
               this.$emit('deleteDerivedParam',this.derivedParam)

              axios.post(this.$store.state.api+'deleteDerivedParam',this.derivedParam,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.$store.dispatch('refreshDerivedParams')
                            this.loading=false
                            this.info='The Param has been deleted'
                            this.showDismissibleAlert=true
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true

                    })
                   


           }else{
               this.info='You do not have permission to do this'
               this.showDismissibleAlert=true

           }
          
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>
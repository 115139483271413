<template>
<v-lazy
        v-model="isActive"
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
      >
    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" class="px-1 py-0 mx-0 my-0 text-capitalize  lighten-4" :color="'indigo'"  @click="dialog = true" >
     
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-container>
            <v-card-title>{{name}} </v-card-title>


<v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card>
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark" 
          color="primary"
        >
          <v-btn
            icon
            :dark="$store.getters.getColorPalette().isDark" 
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{computedParam.name ?computedParam.name : computedParam.param_id}} Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :dark="$store.getters.getColorPalette().isDark" 
              text
              @click="dialog = false"
            >
           {{   $store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
            <v-row >
              <v-col>
                <a hidden ref="csvDownload"></a>
          <v-btn
            color="success"
            @click="downloadPDF()"
          >
          {{   $store.getters.getTextMap().download_latest_usage_table}} 
          </v-btn>
            </v-col>
            <v-spacer></v-spacer>
                <v-col align="right">
                    <span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
        <v-icon
        small
        class="mr-2"
        v-if="$store.state.user && Number($store.state.user.user_level) > $store.state.betaAccessLevel"
        @click="editShow=!editShow"
      >
        mdi-pencil
      </v-icon>
       <DeleteConfirmation v-on:confirm="deleteComputedParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
      <v-icon
        small
      :color="$store.getters.getColorPalette().deletebtnColor"
      >
        mdi-delete
      </v-icon>
      </DeleteConfirmation>
     
    </span>
                </v-col>
          
        </v-row>

        <v-divider></v-divider>
        <v-row v-if="editShow">
          <v-col><EditComputedParam v-on:close="editShow=false" :computedParam="computedParam" /> </v-col>
        </v-row>
        <v-row v-if="currentData && currentData.length>0">
            <v-col>
                <BarGraphByDate :label="name" :graphData="currentData" dataKey="total" :unit="computedParam.unit" />
            </v-col>
        </v-row>
        <v-divider></v-divider>
        
        </v-container>
        
    </v-card>
        </v-dialog>
        
        </v-container>
    </v-card>
</v-lazy>
</template>

<script>
import BarGraphByDate from '@/components/graphs/BarGraphByDate'
import EditComputedParam from '@/components/crud_components/EditComputedParam'
import InfoAlert from '@/components/InfoAlert'
// import jsPDF from 'jspdf'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import moment from 'moment'
import axios from 'axios'
// import 'jspdf-autotable'
// import { applyPlugin } from 'jspdf-autotable'
// applyPlugin(jsPDF)

export default {
    name:'ComputedParam',
    props:['computedParam'],
    components:{
      EditComputedParam,
      BarGraphByDate,
      DeleteConfirmation,
      InfoAlert
    },
    data(){
        return {
            overlay:false,
            dialog:false,
            editShow:false,
            info:'Error',
            showDismissibleAlert:false,
            isActive:false,
            showDialog:false, 
        }
    },
    computed: {
        currentData(){
           return this.$store.state.currentComputedParamUsage[this.computedParam.param_id.toString()]
        },
        name(){
            return this.computedParam.name ? this.computedParam.name : this.computedParam.unit
        },
        latestData(){
            if(this.currentData && this.currentData.length>0){
                return this.currentData[this.currentData.length-1]
            }else{
                return null
            }
        }
        
    },
    methods: {
        downloadPDF(){
          //let pdf = new jsPDF('landscape','pt',[512,600]);
          //  let pdf = new jsPDF();
            //pdf.table(20, 30, this.currentData);
            let header=['Date',this.name]
            let body=[]
            for (let i of this.currentData){
                let temp=[]
                temp.push(moment(i['date'],'YYYYMMDD').format('MMMM Do YYYY'))
                temp.push((Math.round(Number(i['total'])*100)/100))
                
                body.push(temp)
            }
//             pdf.autoTable({
//   head: [header],
//   body: body,
// })
//             pdf.save(this.name+'_'+moment().format('YYYYMMDDHHmm')+'.pdf');
            let csvHeader=header.join(',')+"\n"
            let csv=csvHeader
            csv+=body.map(e => e.join(",")).join("\n");
            let csvData = new Blob([csv], { type: 'text/csv' });  
            let csvUrl = URL.createObjectURL(csvData);
            //let hiddenElement = document.createElement('a');
            let hiddenElement = this.$refs.csvDownload;
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            hiddenElement.download = this.name+'_'+moment().format('YYYYMMDDHHmm') + '.csv';
            hiddenElement.click();
        },
        deleteComputedParam(){
          if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
               this.$store.dispatch('refreshComputedParameters',this.computedParam)
               this.$emit('deleteComputedParam',this.computedParam)
               this.loading=true
          axios.post(this.$store.state.api+'deleteComputedParam',this.computedParam,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        
        this.loading=false
        this.info="Computed Param removed"
        this.showDismissibleAlert=true
            this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      // console.error(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });
           }else{
               this.info='You do not have permission to do this'
               this.showDismissibleAlert=true

           }
          
        },
         cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>
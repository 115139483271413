<template>

    <v-container fluid>
        <v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorName"  >
            <v-card-subtitle>
        <v-row >
            <v-col align="left">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().workspaces}}</strong>
           <br>
            <span class="text-h3"> {{$store.state.workspaces.length}}</span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
            <v-col align="left">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().devices}}</strong>
           <br>
            <span class="text-h3"> {{devices}}</span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
           
            <v-col align="left">
            <strong class="text-subtitle-2">{{$store.getters.getTextMap().parameters}}</strong>
           <br>
            <span class="text-h3"> {{$store.state.params.length}}</span>
          </v-col>
        </v-row>
            </v-card-subtitle>
         </v-card>
    </v-container>
   
</template>
<script>
export default {
    name:'DeviceNumberPanel',
    components:{},
    data(){
        return {}
    },
    computed:{
        devices(){
            let devLength=this.$store.state.devices.length
            devLength+=this.$store.state.vdevices.length
            devLength+=this.$store.state.s7Devices.length
            devLength+=this.$store.state.mqttDevices.length
            return devLength
        }
    },
    methods:{}
}
</script>
<style scoped>

</style>
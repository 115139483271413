<template>
  <div class="text-center">
    <v-btn
    :background-color="$store.getters.getColorPalette().background1ColorCode"
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    :dark="$store.getters.getColorPalette().isDark"
                    outlined
      @click="dialog = !dialog"
    >
    {{$store.getters.getTextMap().add_parameters}} <v-icon
      right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode"
      >mdi-code-array
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <CreateMQTTParam :device="device" v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import CreateMQTTParam from '@/components/crud_components/CreateMQTTParam'
export default {
    name:'CreateParameterBottomSheet',
    props:['device'],
    components:{
      CreateMQTTParam  
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>
<style>

.devc{
  border-radius:20px;
}

</style>
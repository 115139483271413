<template>
    <v-container fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" >
      <v-card-title>{{$store.getters.getTextMap().edit_computed_parameter}}</v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
          
    <v-form
            ref="paramForm"
            v-model="valid"          
        >
        <v-row>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            v-if="!loading"
      v-model="form.name"
      :counter="20"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
            
            <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        v-if="!loading"
                        v-model="form.correction_factor"
                        :counter="8"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().correction_factor"
                        required>

                        </v-text-field>
                    </v-col>
                    <v-col>
                       <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        v-if="!loading"
          v-model="form.quantization"
          :items="quantizations"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().quantization"
         :rules="selectRules"
         required
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                    </v-col>
        </v-row>
        
    </v-form>
      </v-card-subtitle>
      <v-card-actions>
          <v-checkbox
          v-if="!loading"
      v-model="form.engineerAccessOnly"
      :label="$store.getters.getTextMap().restrict_user_access"
    ></v-checkbox>
         
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            
            @click="onSubmit"
          >
           {{ $store.getters.getTextMap().update}}
          </v-btn>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{ $store.getters.getTextMap().close}}
          </v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'EditComputedParam',
    props:['computedParam'],
    components:{
        InfoAlert
    },
    mounted() {
        this.form=Object.assign({}, this.computedParam)
    },
    data(){
        return{
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            form:{
                name:null,
                parameter:null,
                quantization:null,
                multiplier:'time',
                correction_factor:null
            },
            quantizations:[
                {label:'second',value:1},
                {label:'minute',value:60},
                {label:'hour',value:3600},               
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 20 && v.length > 0) || 'Name must be less than 20 characters',
            ],
            floatRules:[
                v => !!v || 'Required',
                v => (v && !isNaN(Number(v))) || 'It should be a number',
                v => (v && v>0 )|| 'It should be more than zero',
                v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
        }
    },
    methods: {
        onSubmit(){
            this.$refs.paramForm.validate()
            if(this.valid){
              this.loading=true
            axios.post(this.$store.state.api+'updateComputedParam',this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshComputedParameters')
        this.info = "Param updated";
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
        this.onReset()
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
            }
        },
        onReset(){
            this.$refs.paramForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    },
}
</script>
<template>

    <v-dialog
      v-model="dialog"      
      
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn  v-bind="attrs"
          v-on="on" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  >{{$store.getters.getTextMap().edit_device}}
        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
      </v-btn>
      </template>
      <v-form
    ref="groupForm"
    v-model="valid"
  >
      <v-card :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">

        <v-card-title class="headline">
          {{$store.getters.getTextMap().edit_modbus_device}}
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
  
<v-container fluid>
 <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />

                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
           <v-text-field
                    :loading="loading"
                    v-model="form.name"
                    :counter="30"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"

                    required
                    ></v-text-field>
                    </v-col>
                 
                    <v-col>
                     <v-select
          v-model="form.mdevice_type"
          :items="mDeviceTypes"
          item-text="type"
          item-value="id"
          :label="$store.getters.getTextMap().device_type"

         :rules="selectRules"
         required
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
 </v-col>
                     <v-col >
                    <v-select
          v-model="form.connection_type"
          :items="connectionTypes"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().connection_type"
         :rules="selectRules"
         required
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
         </v-col>
         <v-col v-if="form.connection_type=='mtcp'">
                    <v-select
          v-model="mGate"
          :items="mGates"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().mgate"

         :rules="selectRules"
         required
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
          
        ></v-select>
                  </v-col>

<v-col>
                      <v-text-field
      v-model="form.msg"
      :counter="35"
      :rules="msgRules"
      :label="$store.getters.getTextMap().description"
      required
    ></v-text-field>
</v-col>
<v-col>
                        <v-text-field
                      :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
      v-model="form.make"
      :counter="35"
     :rules="nameRules"
     :label="$store.getters.getTextMap().make"
      required
    ></v-text-field>

                    </v-col>
                     <v-col>
                        <v-text-field
                      :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
      v-model="form.model"
      :counter="35"
      :rules="msgRules"
      :label="$store.getters.getTextMap().model"
      required
    ></v-text-field>

                    </v-col>
           </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>
 
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                  
                    <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
         
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}

          </v-btn>
                  

          <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}

          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
      
</template>

<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'

  export default {
      name:'EditDeviceBottomModal',
      props:['device'],
      components:{
 InfoAlert,
      },
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 32 && v.length > 0) || 'Name must be less than 33 characters',
      ],
    
      msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something'
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      connectionTypes:[
        {value:'mtcp',label:'Modbus TCP'},
        {value:'mrtu',label:'Modbus RTU'},
      ],
      form:{},
      mGate:null
      }
    },
     mounted(){
        this.form={
                name:this.device.name,
                msg:this.device.msg,
                connection_type:this.device.connection_type ,
                mdevice_type:this.device.mdevice_type,
                unit_id:this.device.unit_id,
                mgate_id:this.device.mgate_id,
                port:this.device.mGate,
                make:this.device.make,
                model:this.device.model,
                
              
                             
        }
    if(this.device.connection_type=='mtcp' && this.device.mgate_id){
        this.mGate=this.$store.getters.getMGateById(this.device.mgate_id)
       
      }
       
    },
     computed:{
      
         mDeviceTypes(){
          return this.$store.state.mDeviceTypes
        },
        mGates(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            let m=[]
            for (let i of this.$store.getters.getMGates()){
              m.push({
                label:i['name'],
                value:i
              })
            }
            return m
        },
    },
    methods: {
        onSubmit(){
            //evt.preventDefault();
            this.$refs.groupForm.validate()
            if(this.valid){
            this.loading = true;
            this.form.device_id=this.device.device_id
            let d=Object.assign({}, this.form)
            if(this.form.connection_type=='mtcp'){
              d['mgate_id']=this.mGate.mgate_id
              
            }
            
      axios.post(this.$store.state.api + "updateDevice", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshDevices");
            
           
            this.info = "changes has been updated";
          this.showDismissibleAlert = true;
          //this.loading = false;
            this.dialog=false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
      onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            //this.$refs.groupForm.reset()
            this.dialog=false
            this.loading=false
            this.info=''
        }
   
  }
  }
</script>
<template>
    <v-container fluid  >
      <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
      <v-row v-if="loading">
          <v-col align="center">
            <v-progress-circular
                indeterminate
                v-if="loading"
                color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-form ref="orgForm" v-model="valid">
          <v-row v-if="editDialog">
            <v-col v-if="editDialog">
              <span>
                <v-text-field
                  :loading="loading"
                  v-model="form.name"
                  :counter="30"
                  :rules="nameRules"
                  :label="$store.getters.getTextMap().name"
                  required
                ></v-text-field>
              </span>
            </v-col>
            <v-col >
              <span>
                <v-text-field
                  :loading="loading"
                  v-model="form.display_seq"
                  :label="$store.getters.getTextMap().sequence"
                  required
                ></v-text-field>
              </span>
            </v-col>
            <v-col v-if="editDialog" align="right">
              <span>
                <v-text-field 
                  v-model="form.msg"
                  :counter="35"
                  :rules="msgRules"
                  :label="$store.getters.getTextMap().description"
                  required
                >
                </v-text-field>
                <v-btn :color="$store.getters.getColorPalette().submitbtnColor" outlined @click="onSubmit">{{ $store.getters.getTextMap().submit }}</v-btn>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col  v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])" align-self="center">
            <v-chip
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
            ><v-avatar
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                color: $store.getters.getColorPalette().background2ColorCode,
              }"
              :class="
                $store.getters.getColorPalette().foregroundColorName +
                ' mx-1 px-0'
              "
              ><v-icon
                :color="$store.getters.getColorPalette().accentCode"
                small
                >mdi-folder-outline</v-icon
              ></v-avatar
            >{{ workspace.workspace_id}}</v-chip
          >
           </v-col>
            <v-col align="right">
              <v-btn v-if="!loading && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="editDialog=!editDialog" >{{ $store.getters.getTextMap().edit_workspace }}
                <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
              </v-btn>
                          <!-- <v-icon
                        
                          v-if="!loading && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"
                  small
                  color="blue lighten-1"
                  @click="editDialog=!editDialog"
                >
                  mdi-pencil
                </v-icon> -->
              <DeleteConfirmation  v-on:confirm="deleteWorkspace()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">        
                <v-btn class="ml-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{ $store.getters.getTextMap().delete_workspace }}<v-icon v-if="!loading && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon></v-btn>
              </DeleteConfirmation>
            </v-col>
          </v-row>
          <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
        </v-form>
        <v-row>
          <v-col cols="auto" class="d-flex"  >
            <CreateDeviceBottomModal :workspace="workspace" v-if="!loading && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
            <CreateS7DeviceModal class="mx-1" :workspace="workspace" v-if="!loading && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
            <CreateVDeviceBottomSheet class="mx-1" :workspace="workspace" v-if="!loading && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
            <CreateMQTTDeviceBottomModal :workspace="workspace" v-if="!loading && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" />
          </v-col>
          <!-- <v-col>
          </v-col>
          <v-col>
          </v-col> -->
        </v-row>
        <!-- <div v-if="!loading && nodes.length>0 && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">Control Nodes</div> -->
          <!-- <NodeList v-if="!loading" :nodes="nodes" v-on:deleteNode="deleteNode" /> -->
        <!-- <div v-if="!loading && devices.length>0 && $store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">Devices</div> -->
          <DeviceList v-if="!loading" :devices="devices" :mqttDevices="mqttDevices" :vDevices="vDevices" :s7Devices="s7Devices" />
        <!-- <div v-if="!loading && snodes.length>0  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">Monitoring Devices</div>
          <SNodeList v-if="!loading" :snodes="snodes" /> -->
    </v-container>
</template>
<script>
// import NodeList from '@/components/lists/NodeList'
import DeviceList from '@/components/lists/DeviceList'
// import SNodeList from '@/components/lists/SNodeList'
import CreateDeviceBottomModal from '@/components/modals/CreateDeviceBottomModal'
import CreateMQTTDeviceBottomModal from '@/components/modals/CreateMQTTDeviceBottomSheet'
import CreateVDeviceBottomSheet from '@/components/modals/CreateVDeviceBottomSheet'
import CreateS7DeviceModal from '@/components/modals/engineering/CreateS7DeviceModal'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
export default {
    name:'Workspace',
    props:['workspace'],
    components:{
        // NodeList,
        DeviceList,
        CreateDeviceBottomModal,
        CreateMQTTDeviceBottomModal,
        CreateVDeviceBottomSheet,
        CreateS7DeviceModal,
        // SNodeList,
        InfoAlert,
        DeleteConfirmation
    },
    mounted(){
        this.form=Object.assign({},this.workspace)
    },
    data(){
        return {
          showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            editDialog:false,
            valid:false,
            form:{
                name:null
            },
            nameRules: [
        v => !!v || this.$store.getters.getTextMap().name_is_required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
        v => (v && v.length <= 30 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_31_characters,
      ],
       msgRules: [
        v => !!v || this.$store.getters.getTextMap().description_is_required,
        v => (v && v.length <= 35 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_35_characters,
      ],
            
            }
    },
    computed:{
        // nodes(){
        //     //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
        //     return this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id)
        // },
        devices(){
            return this.$store.getters.getDevicesByWorkspaceId(this.workspace.workspace_id)
        },
        vDevices(){
            return this.$store.getters.getVDevicesByWorkspaceId(this.workspace.workspace_id)
        },
        s7Devices(){
            return this.$store.getters.getS7DevicesByWorkspaceId(this.workspace.workspace_id)
        },
        mqttDevices(){
          return this.$store.getters.getMQTTDevicesByWorkspaceId(this.workspace.workspace_id)
        },
        // snodes(){
        //     return this.$store.getters.getSNodesByWorkspaceId(this.workspace.workspace_id)
        // }
    },
    methods: {
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
      axios
        .post(this.$store.state.api + "updateWorkspace", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshWorkspaces");
            
           
            this.info = "changes made";
          this.showDismissibleAlert = true;
          //this.loading = false;
            this.editDialog=false;
          } 
          else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        // deleteNode(node){
        //     //console.log(this.$store.state.user.user_level)
        //    if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
        //        this.$store.dispatch('deleteNode',node)
        //        this.showDismissibleAlert=true
        //        this.info='Delete node initiated. Please wait for the system to sync.'
        //    }else{
        //        this.showDismissibleAlert=true
        //        this.info='You do not have access for deleting a node from workspace'
        //    }
        //    //this.$emit('deleteNode',node)
           
        // },
        deleteWorkspace(){
            if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
                this.loading=true
                axios.post(this.$store.state.api+"deleteWorkspace",this.workspace,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt }}).then(response=>{
          if(response.data.status=='success'){
              this.loading=false
              this.$store.dispatch("refreshWorkspaces")
               this.info="The workspace has been removed. Please wait for the system to sync."
               this.showDismissibleAlert=true

          }else{
              this.loading=false
              this.showDismissibleAlert=true
               this.info=response.data.msg
          }
          this.loading=false
      }).catch(err=>{
          this.loading=false
          this.showDismissibleAlert=true
               this.info=err
      })

            }else{
                this.showDismissibleAlert=true
               this.info='You do not have access for deleting the workspace'
            }
        },
      cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }    

    },
    
    }


</script>
<template>
    <v-container fluid  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-card flat :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
      <v-card-title>{{$store.getters.getTextMap().s7_param}}</v-card-title>
      <v-divider></v-divider>
      <v-card-subtitle>
    <v-form
            ref="paramForm"
            v-model="valid"          
        >
        <v-row>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="40"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
          
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.db_number"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().db_number"
      required
    ></v-text-field>
                    </v-col>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.byte_offset"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().byte_offset"
      required
    ></v-text-field>
                    </v-col>
          <v-col v-if="form.val_type=='bool'">
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.bit_offset"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().bit_offset"
      required
    ></v-text-field>
    </v-col>
          <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
             :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.data_length"
      :counter="6"
      :rules="[rules.required,rules.unum, rules.noSpace]"
      :label="$store.getters.getTextMap().data_length"
      required
    ></v-text-field>
                    </v-col>

                <v-col>
                    <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text"
                    ></v-skeleton-loader>
                    <v-text-field
                     
                     :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.msg"
                    :counter="45"
                    
                    :label="$store.getters.getTextMap().description"
                    required>
                    </v-text-field>
                </v-col>

                    
        </v-row>
        <v-row>
            <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.val_type"
          :items="valTypes"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().val_type"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
            <v-col >
   <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
 :dark="$store.getters.getColorPalette().isDark"
                      :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.unit"
                        :counter="5"
                        
                        :label="$store.getters.getTextMap().unit"
                        required>

                        </v-text-field>
                    </v-col>
                    <v-col >
                        <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        type="text"
    ></v-skeleton-loader>
                        <v-text-field
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.correction_factor"
                        :counter="12"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().correction_factor"
                        required>

                        </v-text-field>
                    </v-col>

                     <v-col>
                       <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                      :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.latency"
          :items="latency"
          item-text="label"
          item-value="value"
          label="Idle Latency"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                    <v-col v-if="form && form.enable_entropy" >
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
      ></v-skeleton-loader>
      <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading"
                        v-model="form.entropy"
                        :counter="6"
                        :rules="floatRules"
                        label="Entropy"
                        required>

                        </v-text-field>
                    </v-col>
                    <v-col v-if="form.command!=1">
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.processing"
          :items="processing"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().data_processing"
         :rules="selectRules"
         required
          
          clearable
     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                </v-row>
    </v-form>
      </v-card-subtitle>
      <v-card-actions>
        <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.enable_entropy"
      :label="$store.getters.getTextMap().enable_entropy"
    ></v-checkbox>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.remove_decimal"
      :label="$store.getters.getTextMap().remove_decimal"
    ></v-checkbox>
          <v-checkbox
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.engineerAccessOnly"
      :label="$store.getters.getTextMap().restrict_user_access"
    ></v-checkbox>
          
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.rem_zero"
      :label="$store.getters.getTextMap().no_zero_read"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.rem_negative_entropy"
      :label="$store.getters.getTextMap().remove_negative_entropy"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.calculate_consumption"
      :label="$store.getters.getTextMap().calculate_consumption"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.disabled"
      :label="$store.getters.getTextMap().disabled"
    ></v-checkbox>
          <v-checkbox
        :dark="$store.getters.getColorPalette().isDark"
          :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.entropy_limiter"
      :label="$store.getters.getTextMap().limit_entropy"
    ></v-checkbox>
    <v-text-field
      :dark="$store.getters.getColorPalette().isDark"
      :color="$store.getters.getColorPalette().inputtextColor"
      v-if="!loading && form.entropy_limiter"
      v-model="form.entropy_lim_val"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().limiter_value"
      required
    ></v-text-field>
    
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{param? $store.getters.getTextMap().update:$store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
             :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateS7Param',
    props:['device','param'],
    components:{
      InfoAlert
    },
    mounted(){
      if(this.param && this.param.param_id){
        this.form={...this.param}
        this.api='updateS7Param'
      }else{
        this.api='createS7Param'
      }
    },
    data(){
        return {
            valid:false,
            loading:false,
            info:'',
            api:'createS7Param',
            showDismissibleAlert:false,
            form:{
                name:null,
                slot:null,
                ip:null,
                port:null,
                rack:null,
            },
            processing:[
              {label:'Average',value:'mean'},
                {label:'Difference',value:'difference'},
                {label:'Negative Difference',value:'negative_difference'},
            ],
            latency:[
                {label:'5 minutes',value:300},
                {label:'15 minutes',value:900},
                {label:'Half hourly',value:1800},
                {label:'Hourly',value:3600},
                {label:'Quarter Day',value:21600},
                {label:'Half Day',value:43200},
                {label:'Daily',value:86400}                
            ],
            valTypes:[
              {label:'Bool',value:'bool'},
              {label:'Integer',value:'int'},
              {label:'Real(float)',value:'float'},
              // {label:'String',value:'string'},
            ],
            rules:FormRules.rules,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 40 && v.length > 0) || 'Name must be less than 40 characters',
            ],
            floatRules:[
                v => !!v || 'Required',
                v => (v && !isNaN(Number(v))) || 'It should be a number',
                v => (v && v>0 )|| 'It should be more than zero',
                v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
            ],
            numberRules:FormRules.numberRules,
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
        }
    },
    computed:{
       
    },
    methods:{
      onSubmit(){
            this.$refs.paramForm.validate()
            if(this.valid){
              this.loading=true
            let d=Object.assign({}, this.form)
            if(this.param && this.param.param_id){
              d.param_id=this.param.param_id
            }else{
              d.device_id=this.device.device_id
            }

            

            axios.post(this.$store.state.api+this.api,d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshS7Params')
        this.onReset()
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
            }
        },
        onReset(){
          if(!(this.param && this.param.param_id)){
            this.$refs.paramForm.reset()
          }
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    }

}
</script>
<style scoped>

/* .v-sheet.v-card {
   background-color: #263238;
} */
</style>
<template>
  <v-row justify-end>
      <v-col>
    <v-dialog
      v-model="dialog"
      
      max-width="290"
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn  class="mt-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined   v-bind="attrs"
          v-on="on" >
        {{ $store.getters.getTextMap().create_workspace }}<v-icon  right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-folder-outline</v-icon>
      </v-btn>
      </template>
      <v-form
    ref="groupForm"
    v-model="valid"
    
  >
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <v-card-title class="headline">
          {{ $store.getters.getTextMap().create_workspace }}
        </v-card-title>
        <v-card-text>
            <v-text-field
      v-model="form.name"
      :counter="35"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
            <v-text-field
      v-model="form.display_seq"
      :label="$store.getters.getTextMap().sequence"
      required
    ></v-text-field>
            
    <v-select
          v-model="form.hub"
          :items="$store.state.hubs"
          item-text="hub_id"
          item-value="hub_id"
          :label="$store.getters.getTextMap().hub_id"
         :rules="idRules"
         required
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
          clearable
        ></v-select>
    <v-text-field
      v-model="form.msg"
      :counter="35"
      :rules="msgRules"
      :label="$store.getters.getTextMap().description"
      required
    ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
           {{  $store.getters.getTextMap().create }}
          </v-btn>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{  $store.getters.getTextMap().cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
      </v-col>
  </v-row>
</template>

<script>
  export default {
      name:'CreateWorkspaceModal',
    data () {
      return {
        dialog: false,
        valid:false,
        nameRules: [
        v => !!v || this.$store.getters.getTextMap().name_is_required,
        v => /\S+/.test(v) ||this.$store.getters.getTextMap().name_is_required,
        v => (v && v.length <= 45 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_45_characters,
      ],
        idRules: [
        v => !!v || this.$store.getters.getTextMap().id_is_required,
        v => /^\S+$/.test(v) || this.$store.getters.getTextMap().no_space_allowed,
        v => (v && v.length <= 6 && v.length > 0) || this.$store.getters.getTextMap().id_must_be_less_than_6_characters,
      ],
        msgRules: [
        v => !!v || this.$store.getters.getTextMap().description_is_required,
        v => (v && v.length <= 35 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_35_characters,
      ],
        form:{
                
                msg:null,
                name:null,
                hub:null,
                //floor:null
                
            }
      }
    },
    methods:{
        onSubmit(){
            this.$refs.groupForm.validate()
            if(this.valid){
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
                this.$store.dispatch('addWorkspace', d)
            this.onReset()
            this.dialog=false
            this.$emit('close')
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.groupForm.reset()
            this.dialog=false
        }

    }
  }
</script>
<style scoped>
.v-btn--fab.v-size--default{
  height: 34px;
    width: 33px;
}
</style>
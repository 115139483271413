<template>
    <div class="text-center">
      <v-btn
      :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
        @click="dialog = !dialog"
      >
      {{$store.getters.getTextMap().add_parameters}}<v-icon
        right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small
        >mdi-code-array
        </v-icon>
      </v-btn>
      <v-bottom-sheet v-model="dialog">
        <v-sheet
          class="text-center"
          
          
        >
        <CreateS7Param :device="device" v-on:close="dialog=false"/>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </template>
  <script>
  import CreateS7Param from '@/components/crud_components/engineering/CreateS7Param'
  export default {
      name:'CreateS7ParamModal',
      props:['device'],
      components:{
        CreateS7Param  
      },
      data(){
          return {
              dialog:false
          }
      },
      methods:{
          
      }
  }
  </script>
  
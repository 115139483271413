<template>
  <div>
    <v-card
      flat
      :color="$store.getters.getColorPalette().backgroundColorCode"
      :style="{
        border: `1px solid ${$store.getters.getColorPalette().accentCode}`,
      }"
      :dark="$store.getters.getColorPalette().isDark"
      class="px-10 py-5"
    >
      <div class="d-flex justify-space-between">
        <h2 align="left" class="pb-4">{{$store.getters.getTextMap().enable_disable_all_parameters }}</h2>

        <v-btn
          @click="closeDialog"
          :color="$store.getters.getColorPalette().submitbtnColor"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>



      <v-data-table
        :headers="headers"
        :dark="$store.getters.getColorPalette().isDark"
        :style="{
          border: `1px solid ${$store.getters.getColorPalette().accentCode}`,
        }"
        :items="paramData"
      >
      </v-data-table>

      <div class="d-flex justify-center mt-15">
        <div class="pr-2">
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="enableOnsubmit"
            >{{$store.getters.getTextMap().enable}}</v-btn
          >
        </div>
        <div class="pl-2">
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            @click="disableOnsubmit"
            >{{$store.getters.getTextMap().disable}}</v-btn
          >
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DisableOrEnableParameters",
  props: {
    parameters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "S.No", align: "center", value: "count" },
        { text: "Parameter Name", align: "center", value: "name" },
        { text: "Status", align: "center", value: "status" },
      ],
      form: {
        parameter: [],
      },
      enable: false,
      disable: false,
    };
  },
  computed: {
    paramData() {
      let params = [];
      if (this.parameters && this.parameters.length > 0) {
        this.parameters.forEach((i, index) => {
          const status = i.disabled ? "Disabled" : "Enabled";

          params.push({
            name: i.name,
            status: status,
            count: index + 1,
          });
        });
      }
      return params;
    },
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    enableOnsubmit() {
      this.enable = true;
      this.onSubmit();
    },
    disableOnsubmit() {
      this.disable = true;
      this.onSubmit();
    },
    onSubmit() {
      if (this.parameters && this.parameters.length > 0) {
        this.parameters.forEach((param) => {
          if (this.enable) {
            param.disabled = false;
          }

          if (this.disable) {
            param.disabled = true;
          }
        });

        const updatedParams = this.parameters.map((param) => ({
          param_id: param.param_id,
          disabled: param.disabled,
        }));


        axios
          .post(
            this.$store.state.api + "updateParams",
            { updateSequence: updatedParams },
            { headers: { Authorization: "Bearer " + this.$store.state.jwt } }
          )
          .then((response) => {
            if(response.data.status == "success"){
              this.disable = false;
              this.enable = false;
            }
          })
          .catch((err) => {
            this.disable = false;
            this.enable = false;
            console.log(err);
          });
      }
    },
  },
};
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
} */
</style>

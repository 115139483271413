<template>
    <v-container class="" fluid>
        <v-row>
        <v-col align-self="center" align="left" v-bind:key="manualParam.param_id" v-for="manualParam in manualParamList">
            
            <ManualParameter :manualParameter="manualParam" />
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ManualParameter from '@/components/items/ManualParameter';
export default {
    name:'ManualParamList',
    props:['device'],
    components:{
        ManualParameter
    },
    data(){
        return {}
    },
    computed:{
        manualParamList(){
            //let l=[]
            
            let manualParams=this.$store.getters.getManualParametersByDeviceID(this.device.device_id)
            if(Number(this.$store.state.user.user_level) > this.$store.state.engineerAccessLevel){
                return manualParams
            }else{
                let p=[]
                for(let i of manualParams){
                    console.log(i.engineerAccessOnly)
                    if(i.engineerAccessOnly == null || !i.engineerAccessOnly){
                        p.push(i)
                    }
                }
                return p
            }
        }
    }
}
</script>
<template>

    <v-dialog
      v-model="dialog"      
      
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn v-bind="attrs"
          v-on="on" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  >
          {{$store.getters.getTextMap().edit}}
<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
      </v-btn>
    </template>
      <v-form
    ref="groupForm"
    v-model="valid"
  >
      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">

        <v-card-title class="headline">
          {{$store.getters.getTextMap().edit_mqtt_device}}

        </v-card-title>
        <v-card-text>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
  
<v-container fluid>
 <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />

                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
          <v-text-field
                    :loading="loading"
                    v-model="form.name"
                    :counter="30"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"
                    required
                    ></v-text-field>   
                    </v-col>
                    <v-col>
                     <v-text-field
      v-model="form.topic"
      :counter="15"
      :rules="topicRules"
      :label="$store.getters.getTextMap().topic"
      required
    ></v-text-field> 
                    </v-col>
                    <v-col>
                    <v-text-field
      v-model="form.identifier"
      :counter="15"
      :rules="topicRules"
      :label="$store.getters.getTextMap().identifier"
      required
    ></v-text-field>  
                    
                    </v-col>
                     <v-col >
                    <v-text-field
      v-model="form.identifier_value"
      :counter="15"
      :rules="textIDRules"
      :label="$store.getters.getTextMap().identifier_Value"
      required
    ></v-text-field>    
         </v-col>
         <v-col>
                 <v-text-field
      v-model="form.msg"
      :counter="35"
      :rules="msgRules"
      :label="$store.getters.getTextMap().description"
      required
    ></v-text-field>  
                  </v-col>
                </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>  
          
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                  
                    <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}
          </v-btn>
                  

          <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
      
</template>

<script>
import axios from 'axios';
import InfoAlert from '@/components/InfoAlert'

  export default {
      name:'EditDeviceBottomModal',
      props:['device'],
      components:{
           InfoAlert,
      },
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 31 characters',
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid slave ID',
          v => (v && v.length <= 4 && v.length > 0) || 'Name must be less than 4 digits',
      ],
       textIDRules:[
          v => !!v || 'Required',
          v => /^[A-Za-z0-9]+$/.test(v) || 'No Special characters or spaces allowed',
          v => (v && v.length <= 15 && v.length > 0) || 'Must be less than 15 characters',
      ],
      topicRules:[
          v => !!v || 'Required',
          v => /^\S+$/.test(v) || 'No spaces allowed',
          v => (v && v.length <= 15 && v.length > 0) || 'Must be less than 15 characters',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something'
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      form:{}
      }
    },
     mounted(){
        this.form={
          name:this.device.name,
          topic:this.device.topic,
           identifier:this.device.identifier,
           identifier_value:this.device.identifier_value,
           msg:this.device.msg
        }
    },
     computed:{
      mqttDevices(){
          return this.$store.getters.getMQTTDevicesByWorkspaceId(this.workspace.workspace_id)
        },
        parameters(){
            let params= this.$store.getters.getMQTTParamsByDeviceID(this.device.device_id)
            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                return params
            }else{
                let p=[]
                for(let i of params){
                    console.log(i.engineer_access_only)
                    if(i.engineer_access_only == null || !i.engineer_access_only){
                        p.push(i)
                    }
                }
                return p
            }

        }
    },      
    methods: {
        onSubmit(){
            //evt.preventDefault();
            this.$refs.groupForm.validate()
            if(this.valid){
            this.loading = true;
            this.form.device_id=this.device.device_id
      axios.post(this.$store.state.api + "updateMQTTDevice", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshDevices");
            
           
            this.info = "changes has been updated";
          this.showDismissibleAlert = true;
          //this.loading = false;
            this.dialog=false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },                                      
      onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            //this.$refs.groupForm.reset()
            this.dialog=false
            this.loading=false
            this.info=''
        }
   
  }
  }
</script>
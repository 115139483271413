<template>
    <v-container class="" fluid>
        
        <v-row>
        <v-col align-self="center" cols="3"  v-bind:key="computedParam.param_id" v-for="computedParam in computedParams">
            <ComputedParam :computedParam="computedParam"/>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ComputedParam from '@/components/items/derived/ComputedParam'
export default {
    name:'ComputedParamList',
    props:['device'],
    components:{
        ComputedParam
    },
    computed:{
        computedParams(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getComputedParamsByDeviceID(this.device.device_id)
        }
    },
}
</script>
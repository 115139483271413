<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
        <v-row>
            <v-col  v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])" align-self="center">
             <v-chip
             :dark="$store.getters.getColorPalette().isDark"
             :color="$store.getters.getColorPalette().background2ColorCode"
             ><v-avatar
               :dark="$store.getters.getColorPalette().isDark"
               :style="{
                 color: $store.getters.getColorPalette().background2ColorCode,
               }"
               :class="
                 $store.getters.getColorPalette().foregroundColorName +
                 ' mx-1 px-0'
               "
               ><v-icon
                 :color="$store.getters.getColorPalette().accentCode"
                 small
                 >mdi-sitemap-outline</v-icon
               ></v-avatar
             >{{ device.device_id}}</v-chip
           >
            </v-col>
            
             <v-col align="right" v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
               <span><EditS7DeviceModal :device="device" /> </span>
             
    <DeleteConfirmation v-on:confirm="deleteDevice()"  title="Delete Confirmation"  description="Are you sure you want to delete this?"> 
     <v-btn class="ml-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_device}}
                         <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                     </v-btn>
       </DeleteConfirmation>
                
                 <v-progress-circular
       indeterminate
       v-if="loading"
       color="primary"
     ></v-progress-circular>
            </v-col>
                 </v-row>
    
                 <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
           
                 <v-row >
      <v-col>
         <strong>{{$store.getters.getTextMap().make}}: </strong>{{ device?device.make:'-' }}
      </v-col>
      <v-col>
         <strong>{{$store.getters.getTextMap().model}}: </strong>{{ device?device.model:'-' }}
      </v-col>
       </v-row>
           
       <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
       <v-row v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " class="pt-5 mb-3" >
                 <v-col cols="auto" class="d-flex">
                     <CreateS7ParamModal :device="device" />
                 </v-col>
                 
                 
         </v-row>
 
 
         <v-divider  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"></v-divider>
 
     
 
        
         <v-row no-gutters v-if="parameters && parameters.length>0">
         <v-col  cols="12" sm="6" md="3" lg="3" xl="2" class="py-0 my-0"   v-bind:key="param.param_id" v-for="param in parameters">
             <S7Param v-on:deleteParameter="deleteParam(param)" :param="param"/>
         </v-col>
         </v-row>
     </v-container>
 </template>
 <script>
 import S7Param from '@/components/items/engineering/S7Param'
 import CreateS7ParamModal from '@/components/modals/engineering/CreateS7ParamModal'

 import InfoAlert from '@/components/InfoAlert'
 import EditS7DeviceModal from '@/components/modals/engineering/EditS7DeviceModal'
 import axios from 'axios'
 import DeleteConfirmation from '@/components/DeleteConfirmation'
 export default {
     name:'Device',
     props:['device'],
     components:{
         S7Param,
         CreateS7ParamModal,
         InfoAlert,
         EditS7DeviceModal,
         DeleteConfirmation,
     },
     mounted(){
         this.form=Object({},this.device)
         console.log(this.form);
     },
     data(){
         return {
              showDialog:false,
             loading:false,
             showDismissibleAlert:false,
             info:'',
             editDialog:false,
             valid:false,
             form:{},
         }
     },
     computed:{
         parameters(){
             let params= this.$store.getters.getS7ParamsByDeviceID(this.device.device_id)
             if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                 return params
             }else{
                 let p=[]
                 for(let i of params){
                     console.log(i.engineer_access_only)
                     if(i.engineer_access_only == null || !i.engineer_access_only){
                         p.push(i)
                     }
                 }
                 return p
             }
 
         },
          
     },
     methods: {
        deleteParam(parameter){
           if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                //this.$store.dispatch('deleteParameter',this.parameter)
                this.$emit('deleteParameter',parameter)
                this.loading=true
           axios.post(this.$store.state.api+'deleteParam',parameter,{headers: {
       Authorization: 'Bearer '+ this.$store.state.jwt
     }})
     .then(response=>{
       if(response.data.status==='success'){
         //this.$store.commit('addParameter',d)
         this.$store.dispatch('refreshS7Params')
        //  this.$store.dispatch('refreshDashboardParams')
         //console.log("Parameter added "+d)
         
         this.loading=false
         this.info="Parameter removed"
         this.showDismissibleAlert=true
             //this.dialog=false
         
     }else{
       //console.log(response.data.msg)
       this.info = response.data.msg;
       //this.info = d;
             this.showDismissibleAlert = true;
       //console.log(response.data.msg)
       this.loading=false
     }})
     .catch(err=>{console.log(err)
     this.info=err
     this.loading=false
     this.showDismissibleAlert=true
     });
 
            }else{
                this.info='You do not have permission to do this'
                this.showDismissibleAlert=true
 
            }
           
         },
         deleteDevice(){
             //console.log('delete Device called')
             this.$emit('deleteDevice',this.device)
 
             if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                 this.loading=true
                 axios.post(this.$store.state.api+"deleteDevice",this.device,{headers: {
       Authorization: 'Bearer '+ this.$store.state.jwt }}).then(response=>{
           if(response.data.status=='success'){
               this.loading=false
               this.$store.dispatch("refreshDevices")
                this.info="The Device has been removed. Please wait for the system to sync."
                this.showDismissibleAlert=true
 
           }else{
               this.loading=false
               this.showDismissibleAlert=true
                this.info=response.data.msg
           }
           this.loading=false
       }).catch(err=>{
           this.loading=false
           this.showDismissibleAlert=true
                this.info=err
       })
 
             }else{
                 this.showDismissibleAlert=true
                this.info='You do not have access for deleting the workspace'
                this.loading=false
             }
             
         },
           cancel(){
           this.showDialog=false
         },
         confirm(){
           this.showDialog=false
         }
     },
 }
 </script>
 <style scoped>
 .col-sm-3{
 flex-basis: 0;
 flex-grow: 1;
 max-width: 100%;
 }
 </style>
<template>
 
    <v-dialog
      v-model="dialog"      
      
    >
      <template v-slot:activator="{ on, attrs }">
        
        <v-btn   icon v-bind="attrs"
          v-on="on" color="blue" >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      </template>

      <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
        <CreateS7Param :param="param" v-on:close="close" />
      </v-card>
      
    </v-dialog>
      
</template>

<script>
import CreateS7Param from '@/components/crud_components/engineering/CreateS7Param';

  export default {
      name:'EditS7ParamModal',
      props:['param'],
      components:{
        CreateS7Param
      },
    data () {
      return {
        dialog: false,
        valid:false,
        info:'',
        showDismissibleAlert:false,
        loading:false,
        
      }
    },
    mounted(){
       

    },
    computed:{
      
    },
    methods:{
        close(){
            this.dialog=false
            this.$emit('close')
        },

        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            //this.$refs.groupForm.reset()
            this.dialog=false
            this.loading=false
            this.info=''
        }
    }
  }
</script>
<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <div class="py-0">
          
          <v-form
    ref="parameterForm"
    v-model="valid" >
      <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">
         {{$store.getters.getTextMap().add_device_parameter}}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
           :dark="$store.getters.getColorPalette().isDark" 
             :color="$store.getters.getColorPalette().inputtextColor"
            v-if="!loading"
      v-model="form.name"
      :counter="30"
      :rules="[rules.required]"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
                    
 <v-col>
   <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.unit"
                        :counter="5"
                        
                        :label="$store.getters.getTextMap().unit"
                        required>

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    
                   
                    
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                        :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.correction_factor"
                        :counter="8"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().correction_factor"
                        required>

                        </v-text-field>
                    </v-col>

                     <v-col>
                       <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
             :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.latency"
          :items="latency"
          item-text="label"
          item-value="value"
          :label="$store.getters.getTextMap().idle_latency"
         :rules="selectRules"
         required
          
          clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                      :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
                        v-model="form.entropy"
                        :counter="6"
                        :rules="floatRules"
                        :label="$store.getters.getTextMap().entropy"
                        required>

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                   
                    <v-col>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-text-field
                       :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
      v-model="form.identifier"
      :counter="15"
      :rules="[rules.required]"
      label="identifier"
      required
    ></v-text-field>
                    </v-col>
                  <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.processing"
          :items="processing"
          item-text="label"
          item-value="value"
          label="Data processing"
         :rules="selectRules"
         required
          
          clearable
   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
                  <v-col>
                    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                        <v-select
                       :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
                        v-if="!loading"
          v-model="form.data_type"
          :items="dataTypes"
          item-text="label"
          item-value="value"
          label="Data Type"
         :rules="selectRules"
         required
          
          clearable
       :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
                    </v-col>
             
                </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions>
          <v-checkbox
          :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.engineer_access_only"
      :label="`Restrict User Access`"
    ></v-checkbox>
          <v-checkbox
           :dark="$store.getters.getColorPalette().isDark" 
              :color="$store.getters.getColorPalette().inputtextColor"
          v-if="!loading"
      v-model="form.aliasing"
      :label="`aliasing`"
    ></v-checkbox>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            Create
          </v-btn>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateParameter',
    props:['device'],
    components:{
        InfoAlert
    },
     data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            byteFormat:['bigEndian','littleEndian'],
            latency:[
                {label:'5 minutes',value:300},
                {label:'15 minutes',value:900},
                {label:'Half hourly',value:1800},
                {label:'Hourly',value:3600},
                {label:'Quarter Day',value:21600},
                {label:'Half Day',value:43200},
                {label:'Daily',value:86400}                
            ],
            processing:[
              {label:'Average',value:'mean'},
                {label:'Difference',value:'difference'}
            ],
            registerSize:[
              {label:'short',value:'short'},
              {label:'long',value:'long'}
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 15 && v.length > 0) || 'Name must be less than 15 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
      textIDRules:FormRules.textIDRules(15),
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      rules:FormRules.rules,
      form:{
                byte_format:null,
                correction_factor:null,
                register_size:'short',
                name:null,
                entropy:null,
                latency:3600,
                unit:null,
                command:null,
                address:null,
                offset:null,
                processing:null,
                engineerAccessOnly:false,
                aliasing:false
                
            }
        }
    },
    computed:{
      dataTypes(){
        let op=[]
        if(this.$store.state.dataTypes){
          for(let i of this.$store.state.dataTypes){
            op.push({
              label:i.type,
              value:i.id
            })
          }
        }
        return op
      }
    },
    methods:{
        onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            d.mgate_id=this.device.mgate_id
            d.port=this.device.port
            d.unit_id=this.device.unit_id
            d.device_id=this.device.device_id
console.log(d)
            axios.post(this.$store.state.api+'createMQTTParam',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        this.$store.dispatch('refreshMQTTParameters')
        //console.log("Parameter added "+d)
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)});
                //this.$store.dispatch('addDevice', d)
            
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }

    }

}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card{
   background-color: #263238;
} */
</style>
<template>
    <v-container class="" fluid>
        
        <v-row>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3"  v-bind:key="derivedParam.param_id" v-for="derivedParam in derivedParams">
            <DerivedParam :derivedParam="derivedParam"/>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DerivedParam from '@/components/items/DerivedParam'
export default {
    name:'DerivedParamList',
    props:['device'],
    components:{
        DerivedParam
    },
    computed:{
        derivedParams(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            return this.$store.getters.getDerivedParamsByDeviceID(this.device.device_id)
        }
    },
}
</script>
<template>
  <div class="text-center">
    <v-btn
    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
      @click="dialog = !dialog"
    >
    {{$store.getters.getTextMap().create_computed_param }} <v-icon
      right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small
      >mdi-code-array
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <CreateComputedParam :device="device" v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CreateComputedParam from '@/components/CreateComputedParam'
export default {
    name:'CreateComputedParameterBottomSheet',
    props:['device'],
    components:{
      CreateComputedParam
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>
<style >
.devc{
  border-radius:20px;
}

</style>
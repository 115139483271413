<template>
  <v-container class="" fluid >
    <v-row no-gutters>
      <v-col cols="12" align="right">
        <v-tabs
          v-model="tab"
          v-if="devices.length > 0"
          right
          :dark="$store.getters.getColorPalette().isDark"
          background-color="transparent lighten-5"
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#list">
            {{$store.getters.getTextMap().list}}
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-tab>

          <v-tab href="#single">
            {{$store.getters.getTextMap().single}}
            <v-icon>mdi-border-all-variant</v-icon>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>


<!-- Working now -->

    <v-row v-if="tab == 'list'">
      <v-col>
        <v-expansion-panels multiple hover tile>
          <v-expansion-panel
            :style="{
              border:
                '1px solid ' + $store.getters.getColorPalette().cardBorderColor,
              'background-color':
                $store.getters.getColorPalette().background2ColorCode,
            }"
            :dark="$store.getters.getColorPalette().isDark"
            v-bind:key="device.device_id"
            v-for="device in devices"
          >
            <v-expansion-panel-header
              ><h3 :color="$store.getters.getColorPalette().accentCode">
                {{ device.name }}
              </h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <Device v-on:del-device="deleteDevice(device)" :device="device" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :style="{
              border:
                '1px solid ' + $store.getters.getColorPalette().cardBorderColor,
              'background-color':
                $store.getters.getColorPalette().background2ColorCode,
            }"
            :dark="$store.getters.getColorPalette().isDark"
            v-bind:key="device.device_id"
            v-for="device in s7Devices"
          >
            <v-expansion-panel-header
              ><h3 >{{ device.name }}</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <S7Device :device="device" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :style="{
              border:
                '1px solid ' + $store.getters.getColorPalette().cardBorderColor,
              'background-color':
                $store.getters.getColorPalette().background2ColorCode,
            }"
            :dark="$store.getters.getColorPalette().isDark"
            v-bind:key="device.device_id"
            v-for="device in vDevices"
          >
            <v-expansion-panel-header
              ><h3 >{{ device.name }}</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <VDevice :vdevice="device" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel
            :style="{
              border:
                '1px solid ' + $store.getters.getColorPalette().cardBorderColor,
              'background-color':
                $store.getters.getColorPalette().background2ColorCode,
            }"
            :dark="$store.getters.getColorPalette().isDark"
            v-bind:key="device.device_id"
            v-for="device in mqttDevices"
          >
            <v-expansion-panel-header
              ><h3>{{ device.name }}</h3></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <MQTTDevice
                v-on:del-device="deleteDevice(device)"
                :device="device"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="tab == 'single'">
      <v-col>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedDevices"
              :items="deviceList"
              item-text="label"
              item-value="value"
              :label="$store.getters.getTextMap().select_device"
              multiple
              required
              clearable
              small-chips
                :color="$store.getters.getColorPalette().inputtextColor"
               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-bind:key="device.device_id" v-for="device in selectedDevices" color="purple">
          <v-col cols="12">
            <v-card :color="$store.getters.getColorPalette(). background2ColorCode">
              <v-card-title>{{ device.name }}</v-card-title>
              <Device :device="device" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Device from "@/components/items/Device";
import MQTTDevice from "@/components/items/MQTTDevice";
import VDevice from "@/components/items/VDevice";
import S7Device from "@/components/items/engineering/S7Device";
export default {
  name: "DeviceList",
  props: {
    devices: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mqttDevices: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vDevices: {
      type: Array,
      default: function () {
        return [];
      },
    },
    s7Devices: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Device,
    VDevice,
    S7Device,
    MQTTDevice,
  },
  data() {
    return {
      tab: "list",
      selectedDevices: [],
    };
  },
  mounted() {},
  methods: {},
  computed: {
    deviceList() {
      let op = [];

      let g = this.devices;
      console.log(g);
      for (let i of g) {
        op.push({ value: i, label: i.name });
      }
      return op;
    },
    mqttDeviceList() {
      let op = [];

      let g = this.mqttDevices;
      for (let i of g) {
        op.push({ value: i, label: i.name });
      }
      return op;
    },
    vDeviceList() {
      let op = [];

      let g = this.vDevices;
      for (let i of g) {
        op.push({ value: i, label: i.name });
      }
      return op;
    },
  },
};
</script>
<style scoped>
.row {
  margin-left: -33px;
  margin-right: -35px;
}
</style>

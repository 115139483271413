<template>
   <v-container fluid >
       <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
       <v-row>
           
           <v-form
    ref="orgForm"
    v-model="valid"
    
  >
           <v-col v-if="editDialog">
                    <span>
                    <v-text-field
                    :loading="loading"
                    v-model="form.name"
                    :counter="30"
                    :rules="nameRules"
                    :label="$store.getters.getTextMap().name"
                    required
                    ></v-text-field>
                    
                    <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            outlined
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().submit}}
          </v-btn>
                    </span>
                </v-col>
           </v-form>
           <v-col align="right" v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
               <v-icon
                
                v-if="!loading"
        small
        color="blue lighten-1"
        @click="editDialog=!editDialog"
      >
        mdi-pencil
      </v-icon>
       <DeleteConfirmation v-on:confirm="deleteDevice()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
                <v-icon
                v-if="!loading"
        small
      
      >
        mdi-delete
      </v-icon>
      </DeleteConfirmation>  
                <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
           </v-col>
       </v-row>
       <v-row v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "  >
           <v-col cols="12" sm="3" md="3" lg="3" xl="3" align="center">
               <CreateOPCParamBottomSheet :vdevice="vdevice" />
           </v-col >
           
           
       </v-row>
       
        <v-row  v-if="parameters && parameters.length>0">
        <v-col cols="12" sm="6" md="3" lg="3" xl="2"  v-bind:key="parameter.parameter" v-for="parameter in parameters">
            <OPCParam  :parameter="parameter"/>
        </v-col>
        </v-row>
    </v-container>
</template>
<script>
import OPCParam from '@/components/items/OPCParam'
import CreateOPCParamBottomSheet from '@/components/modals/CreateOPCParamBottomSheet'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'VDevice',
    props:['vdevice'],
    components:{
        OPCParam,
        CreateOPCParamBottomSheet,
        DeleteConfirmation,
        InfoAlert
    },
    mounted(){
        this.form=Object({},this.vdevice)
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            editDialog:false,
            valid:false,
            form:{},
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 30 && v.length > 0) || 'Name must be less than 31 characters',
      ],
        }
    },
    computed:{
        parameters(){
            let params= this.$store.getters.getOPCParamsByVDeviceID(this.vdevice.device_id)
            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                return params
            }else{
                let p=[]
                for(let i of params){
                    console.log(i.engineer_access_only)
                    if(i.engineer_access_only == null || !i.engineer_access_only){
                        p.push(i)
                    }
                }
                return p
            }

        }
    },
    methods: {
        onSubmit(){
            //evt.preventDefault();
            this.$refs.orgForm.validate()
            if(this.valid){
            this.loading = true;
            this.form.device_id=this.vdevice.device_id
      axios.post(this.$store.state.api + "updateVDevice", this.form,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
        .then(response => {
          if (response.data.status == "success") {
            
            
            //console.log(response.data.jwt);
            this.$store.dispatch("refreshVDevices");
            
           
            this.info = "Device Updated";
          this.showDismissibleAlert = true;
          //this.loading = false;
            this.editDialog=false;
          } else {
            this.info = response.data.msg;
            this.showDismissibleAlert = true;
          }
          //this.onReset()
          this.loading = false;
        })
        .catch(error => {
          this.info = error;
          this.showDismissibleAlert = true;
          this.loading = false;
        });
            }
        },
        deleteParameter(parameter){
            this.$emit('deleteParameter',parameter)
        },
        deleteDevice(){
            //console.log('delete Device called')
            this.$emit('deleteVDevice',this.vdevice)

            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                this.loading=true
                axios.post(this.$store.state.api+"deleteVDevice",this.vdevice,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt }}).then(response=>{
          if(response.data.status=='success'){
              this.loading=false
              this.$store.dispatch("refreshVDevices")
               this.info="The Device has been removed. Please wait for the system to sync."
               this.showDismissibleAlert=true

          }else{
              this.loading=false
              this.showDismissibleAlert=true
               this.info=response.data.msg
          }
          this.loading=false
      }).catch(err=>{
          this.loading=false
          this.showDismissibleAlert=true
               this.info=err
      })

            }else{
                this.showDismissibleAlert=true
               this.info='You do not have access for deleting the workspace'
               this.loading=false
            }
            
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>
<template>
    <span class="text-center">
      <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="dialog=!dialog" >
        {{ $store.getters.getTextMap().create_s7_device }}<v-icon  right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-dots-square</v-icon>
        </v-btn>
           <v-bottom-sheet v-model="dialog">
        <v-sheet
      :dark="$store.getters.getColorPalette().isDark"
          class="text-center"
           :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentcode}"
        >
        <CreateS7Device :workspace="workspace"  v-on:close="dialog=false"/>
        </v-sheet>
      </v-bottom-sheet>
  
    </span>
    
  </template>
  
  <script>
  import CreateS7Device from '@/components/crud_components/engineering/CreateS7Device'
   export default {
       name:'CreateS7DeviceModal',
       props:['workspace'],
       components:{
        CreateS7Device
       },
       data(){
           return {
               dialog:false
           }
       }
   }
   </script>
<template>
    <div>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode"  >
            <v-card-title  :dark="$store.getters.getColorPalette().isDark">
              {{$store.getters.getTextMap().write_register}}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-form
                ref="deviceForm"
                v-model="valid">
                    <v-row>
                        <v-col>
                        <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                    :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
      v-model="form.address"
      :counter="5"
      :rules="nonNegativeRules"
      :label="$store.getters.getTextMap().address"
      required
    ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                     :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
      v-model="offset"
      :counter="5"
      :rules="nonNegativeRules"
      :label="$store.getters.getTextMap().no_of_registers"
      required
    ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="item in valueItems" :key="item">
                        <v-text-field
                  :dark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
      v-model="values[item]"
      :counter="5"
      :rules="numberRules"
      :label="$store.getters.getTextMap().value"
      required
    ></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    
          <v-btn
          v-if="!loading"
             :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
          {{$store.getters.getTextMap().write}}
          </v-btn>
          <v-btn
          v-if="!loading"
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{$store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
export default {
    name:'WriteRegister',
    props:['device'],
    components:{
        InfoAlert
    },
    data(){
        return {
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            values:[],
            offset:1,
            form:{
                address:null
            },
            numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>=0 && v%1==0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'It must be less than 6 digits',
      ],
            nonNegativeRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0 && v%1==0)|| 'It should be a valid number',
         // v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 6 digits',
      ],
        }
    },
    computed:{
        valueItems(){
            if(this.offset && Number.isInteger(Number(this.offset))){
                let items=[]
                for (let i=0;i<Number(this.offset);i++){
                    items.push(i)
                }
                return items
            }else{
                return []
            }
        }
    },
    methods:{
        onSubmit(){
            this.$refs.deviceForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                //TODO - rectify
                d['data']=[]
                for(let i=0;i<Number(this.offset);i++){
                    d['data'].push(this.values[i])
                }
                //d['data']=this.values
                d['device_id']=this.device.device_id
                axios.post(this.$store.state.api+'writeDeviceRegister',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshData')
        
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      console.log(err)
      });
            }
        },
        onReset(){
            this.$refs.deviceForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        }
    }
}
</script>
<style scoped>
.v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
}
</style>
<template>

  <v-container fluid>
    <div class="text-center">

      <div class="d-flex justify-space-between">

        <div>
          <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode"
            small text outlined @click="dialog = !dialog">
            {{$store.getters.getTextMap().enable}} <v-icon class="pr-3" right :isDark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-check-circle-outline
            </v-icon>/ {{$store.getters.getTextMap().disable}} <v-icon right :isDark="$store.getters.getColorPalette().isDark"
              :color="$store.getters.getColorPalette().btnborderColorCode" small class="pr-3">mdi-cancel
            </v-icon>{{$store.getters.getTextMap().all_parameters}}
          </v-btn>
        </div>
      </div>


      <v-dialog v-model="dialog" transition="slide-x-reverse-transition">
        <v-sheet class="text-center">
          <DisableOrEnableParameters :parameters="parameters" @close="closeDialog" />
        </v-sheet>
      </v-dialog>
    </div>


  </v-container>


</template>
<script>

import DisableOrEnableParameters from '@/components/crud_components/DisableOrEnableParameters'
export default {
  name: 'DisableAllParamsBottomSheet',
  props: ['parameters'],
  components: {
    DisableOrEnableParameters,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    }
  }
}
</script>

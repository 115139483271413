<template>
    <v-container class="" fluid>
        <v-row>
        <v-col align="left" v-bind:key="writeParam.parameter" v-for="writeParam in writeParamList">
            <WriteParam :writeParam="writeParam" />
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WriteParam from '@/components/items/WriteParam';
export default {
    name:'WriteParamList',
    props:['device'],
    components:{
        WriteParam
    },
    data(){
        return {}
    },
    computed:{
        writeParamList(){
            //let l=[]
            
            let writeParams=this.$store.getters.getWriteParamsByDeviceID(this.device.device_id)
            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                return writeParams
            }else{
                let p=[]
                for(let i of writeParams){
                    console.log(i.engineerAccessOnly)
                    if(i.engineerAccessOnly == null || !i.engineerAccessOnly){
                        p.push(i)
                    }
                }
                return p
            }
        }
    }
}
</script>
<template>
   <v-container fluid >
       <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
       <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
       <v-row>
           <v-col  v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['betaaccess'])" align-self="center">
            <v-chip
            :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().background2ColorCode"
            ><v-avatar
              :dark="$store.getters.getColorPalette().isDark"
              :style="{
                color: $store.getters.getColorPalette().background2ColorCode,
              }"
              :class="
                $store.getters.getColorPalette().foregroundColorName +
                ' mx-1 px-0'
              "
              ><v-icon
                :color="$store.getters.getColorPalette().accentCode"
                small
                >mdi-sitemap-outline</v-icon
              ></v-avatar
            >{{ device.device_id}}</v-chip
          >
           </v-col>
           <v-col  v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])" align-self="center" >
               <strong>{{$store.getters.getTextMap().unit_id}}:</strong><span :color="$store.getters.getColorPalette().accentCode" > {{device.unit_id}}</span>

           </v-col>
            <v-col align="right" v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
              <span><EditDeviceBottomModal :device="device" /> </span>
            
   <DeleteConfirmation v-on:confirm="deleteDevice()"  title="Delete Confirmation"  description="Are you sure you want to delete this?"> 
    <v-btn class="ml-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_device}}
                        <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small>mdi-trash-can</v-icon>
                    </v-btn>
      </DeleteConfirmation>
               
                <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
           </v-col>
                </v-row>
   
                <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
          
                <v-row >
     <v-col>
        <strong>{{$store.getters.getTextMap().make}}: </strong>{{ device?device.make:'-' }}
     </v-col>
     <v-col>
        <strong>{{$store.getters.getTextMap().model}}: </strong>{{ device?device.model:'-' }}
     </v-col>
      </v-row>
          
      <v-divider :color="$store.getters.getColorPalette().panelBorderColorCode" class="my-3"></v-divider>
      <v-row v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " class="pt-5 mb-3" >
                <v-col cols="auto" class="d-flex">
                    <CreateParameterBottomSheet :device="device" />
                </v-col>
                <v-col cols="auto" class="d-flex" >
                    <CreateParameterFromTemplateSheet :device="device" />
                </v-col>
                <v-col cols="auto" class="d-flex" v-if="$store.getters.getAccessByLevels(['betaaccess'])">
                    <CreateWriteParamBottomSheet class="mx-1"  :device="device" />
                </v-col>
                <v-col cols="auto" class="d-flex">
                    <WriteDeviceRegisterBottomSheet :device="device" />
                </v-col>
                <v-col cols="auto" class="d-flex">
                    <CreateDerivedParamBottomSheet  :device="device" />
                </v-col>
                <v-col cols="auto" class="d-flex">
                    <CreateComputedParamBottomSheet class="mx-1" :device="device" />
                </v-col>
                <v-col cols="auto" class="d-flex">
                    <CreateManualParameterBottomSheet :device="device" />
                </v-col>

                <v-col class="d-flex justify-end " cols="12">
                <DisableAllParamsBottomSheet :parameters="parameters" />
            </v-col>
        </v-row>


        <v-divider  v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])"></v-divider>

    

       <v-row no-gutters v-if=" $store.getters.getWriteParamsByDeviceID(this.device.device_id).length>0" >
           <v-col class="py-6" > 
               <WriteParamList :device="device" /> 
           </v-col>
       </v-row>
       <v-row no-gutters v-if=" $store.getters.getManualParametersByDeviceID(this.device.device_id).length>0">
           <v-col>
               <ManualParamList :device="device" /> 
           </v-col>
       </v-row>
       <v-row no-gutters v-if=" $store.getters.getComputedParamsByDeviceID(this.device.device_id).length>0">
           <v-col cols="12">
               <ComputedParamList :device="device" /> 
           </v-col>
       </v-row>
       <v-row no-gutters v-if=" $store.getters.getDerivedParamsByDeviceID(this.device.device_id).length>0">
           <v-col cols="12">
               <DerivedParamList :device="device" />
           </v-col>
       </v-row>
        <v-row no-gutters v-if="parameters && parameters.length>0">
        <v-col  cols="12" sm="6" md="3" lg="3" xl="2" class="py-0 my-0"   v-bind:key="parameter.parameter" v-for="parameter in parameters">
            <Parameter v-on:deleteParameter="deleteParameter(parameter)" :parameter="parameter"/>
        </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Parameter from '@/components/items/Parameter'
import CreateParameterBottomSheet from '@/components/modals/CreateParameterBottomSheet'
import DisableAllParamsBottomSheet from '@/components/modals/DisableAllParamsBottomSheet'
import CreateComputedParamBottomSheet from '@/components/modals/derived/CreateComputedParamBottomSheet'
import CreateDerivedParamBottomSheet from '@/components/modals/derived/CreateDerivedParamBottomSheet'
import CreateManualParameterBottomSheet from '@/components/modals/CreateManualParameterBottomSheet'
import CreateWriteParamBottomSheet from '@/components/modals/CreateWriteParamBottomSheet'
import WriteDeviceRegisterBottomSheet from '@/components/modals/WriteDeviceRegisterBottomSheet'
import WriteParamList from '@/components/lists/WriteParamList'
import ManualParamList from '@/components/lists/ManualParamList'
import ComputedParamList from '@/components/lists/derived/ComputedParamList'
import DerivedParamList from '@/components/lists/derived/DerivedParamList'
import InfoAlert from '@/components/InfoAlert'
import EditDeviceBottomModal from '@/components/modals/EditDeviceBottomModal'
import axios from 'axios'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import CreateParameterFromTemplateSheet from '@/components/modals/CreateParameterFromTemplateSheet'
export default {
    name:'Device',
    props:['device'],
    components:{
        Parameter,
        CreateParameterBottomSheet,
        CreateComputedParamBottomSheet,
        CreateDerivedParamBottomSheet,
        CreateManualParameterBottomSheet,
        CreateWriteParamBottomSheet,
        WriteDeviceRegisterBottomSheet,
        WriteParamList,
        ManualParamList,
        ComputedParamList,
        DerivedParamList,
        InfoAlert,
        EditDeviceBottomModal,
        DeleteConfirmation,
        CreateParameterFromTemplateSheet,
        DisableAllParamsBottomSheet
    },
    mounted(){
        this.form=Object({},this.device)
        console.log(this.form);
    },
    data(){
        return {
             showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            editDialog:false,
            valid:false,
            form:{},
        }
    },
    computed:{
        parameters(){
            let params= this.$store.getters.getParametersByDeviceID(this.device.device_id)
            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                return params
            }else{
                let p=[]
                for(let i of params){
                    console.log(i.engineer_access_only)
                    if(i.engineer_access_only == null || !i.engineer_access_only){
                        p.push(i)
                    }
                }
                return p
            }

        },
         mDeviceTypes(){
          return this.$store.state.mDeviceTypes
        },
        mGates(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            let m=[]
            for (let i of this.$store.getters.getMGates()){
              m.push({
                label:i['name'],
                value:i
              })
            }
            return m
        },
    },
    methods: {
        deleteParameter(parameter){
          if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
               //this.$store.dispatch('deleteParameter',this.parameter)
               this.$emit('deleteParameter',parameter)
               this.loading=true
          axios.post(this.$store.state.api+'deleteParameter',parameter,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addParameter',d)
        this.$store.dispatch('refreshParameters')
        this.$store.dispatch('refreshDashboardParams')
        //console.log("Parameter added "+d)
        
        this.loading=false
        this.info="Parameter removed"
        this.showDismissibleAlert=true
            //this.dialog=false
        
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{console.log(err)
    this.info=err
    this.loading=false
    this.showDismissibleAlert=true
    });

           }else{
               this.info='You do not have permission to do this'
               this.showDismissibleAlert=true

           }
          
        },
        deleteDevice(){
            //console.log('delete Device called')
            this.$emit('deleteDevice',this.device)

            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                this.loading=true
                axios.post(this.$store.state.api+"deleteDevice",this.device,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt }}).then(response=>{
          if(response.data.status=='success'){
              this.loading=false
              this.$store.dispatch("refreshDevices")
               this.info="The Device has been removed. Please wait for the system to sync."
               this.showDismissibleAlert=true

          }else{
              this.loading=false
              this.showDismissibleAlert=true
               this.info=response.data.msg
          }
          this.loading=false
      }).catch(err=>{
          this.loading=false
          this.showDismissibleAlert=true
               this.info=err
      })

            }else{
                this.showDismissibleAlert=true
               this.info='You do not have access for deleting the workspace'
               this.loading=false
            }
            
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>
<style scoped>
.col-sm-3{
flex-basis: 0;
flex-grow: 1;
max-width: 100%;
}
</style>
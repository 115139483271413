<template>
  <div class="text-center">
    <v-btn
    :background-color="$store.getters.getColorPalette().background1ColorCode"
                    :color="$store.getters.getColorPalette().accentCode"
                    small
                    text
                    :dark="$store.getters.getColorPalette().isDark"
                    outlined
      @click="dialog = !dialog"
    >
    {{$store.getters.getTextMap().add_opc_param}} <v-icon
      right :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode"
      >mdi-code-array
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <CreateOPCParam :vdevice="vdevice" v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CreateOPCParam from '@/components/crud_components/CreateOPCParam'
export default {
    name:'CreateParameterBottomSheet',
    props:['vdevice'],
    components:{
      CreateOPCParam  
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>

<style scoped>
.setng{
  border:3px solid;
  border-radius: 20px;
 
}


</style>
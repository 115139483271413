<template>
  <div class="text-center">
    <v-btn
    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  
      @click="dialog = !dialog"
    >
      {{ $store.getters.getTextMap().add_virtual_device }}
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
        
        <div class="py-0">
          
          <v-form
    ref="deviceForm"
    v-model="valid"
    
  >
      <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
        <v-card-title class="headline">
         {{ $store.getters.getTextMap().add_device }}
        </v-card-title>
        <v-card-text>
            <v-container fluid>
              <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
                <v-row>
                    <v-col>
            <v-text-field
           :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().inputtextColor"
      v-model="form.name"
      :counter="32"
      :rules="nameRules"
      :label="$store.getters.getTextMap().name"
      required
    ></v-text-field>
                    </v-col>
                    
                  
                 
                    <v-col>
                        <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                       :color="$store.getters.getColorPalette().inputtextColor"
      v-model="form.msg"
      :counter="55"
      :rules="msgRules"
      :label="$store.getters.getTextMap().description"
      required
    ></v-text-field>

                    </v-col>
                </v-row>
    </v-container>
        </v-card-text>
        <v-card-actions v-if="!loading">
          <v-spacer></v-spacer>
          <v-btn
          :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onSubmit"
          >
            {{  $store.getters.getTextMap().create}}
          </v-btn>
          <v-btn
         :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="onReset"
          >
          {{  $store.getters.getTextMap().cancel}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'CreateDeviceBottomModal',
    props:['workspace'],
    components:{
      InfoAlert
    },
    data(){
        return {
            dialog:false,
            valid:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            nameRules: [
        v => !!v || this.$store.getters.getTextMap().name_is_required,
        v => /\S+/.test(v) || this.$store.getters.getTextMap().name_is_required,
        v => (v && v.length <= 32 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_33_characters,
      ],
      numberRules:[
          v => !!v ||this.$store.getters.getTextMap().required,
          v => /[0-9]+$/.test(v) || this.$store.getters.getTextMap().it_should_be_a_number,
          v => (v && v>0 && v%1==0)|| this.$store.getters.getTextMap().it_should_be_a_valid_slave_id,
          v => (v && v.length <= 4 && v.length > 0) || this.$store.getters.getTextMap().name_must_be_less_than_4_digits,
      ],
      msgRules: [
        v => !!v || this.$store.getters.getTextMap().description_is_required,
        v => (v && v.length <= 35 && v.length > 0) || this.$store.getters.getTextMap().description_must_be_less_than_35_characters,
      ],
      listRules:[
        v=> !!v ||this.$store.getters.getTextMap().required,
        v => (v && v.length>0) || this.$store.getters.getTextMap().you_have_to_select_something
      ],
      selectRules:[
        v=> (v!=null) || this.$store.getters.getTextMap().required
      ],
      connectionTypes:[
        {value:'mtcp',label:this.$store.getters.getTextMap().modbus_tcp},
        {value:'mrtu',label:this.$store.getters.getTextMap().modbus_rtu},
      ],
      form:{
                
                msg:null,
                name:null,
                unit_id:null,
                mdevice_type:null,
                connection_type:null,
                
            },
      mGate:null
      }
    },
    computed: {
      mGates(){
            //console.log(this.$store.getters.getNodesByWorkspaceId(this.workspace.workspace_id))
            let m=[]
            for (let i of this.$store.getters.getMGates()){
              m.push({
                label:i['name'],
                value:i
              })
            }
            return m
        },
        mDeviceTypes(){
          return this.$store.state.mDeviceTypes
        }
    },
    methods:{
        onSubmit(){
            this.$refs.deviceForm.validate()
            if(this.valid && this.workspace.workspace_id){
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            let d=Object.assign({}, this.form)
            
            
            
            d['workspace_id']=this.workspace['workspace_id']

            d.device_id=Math.floor(Date.now() / 1000).toString()
    axios.post(this.$store.state.api+'createVDevice',d,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        //this.$store.commit('addDevice',d)
        this.$store.dispatch('refreshVDevices')
        console.log("Device added "+d)
        this.onReset()
    }else{
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
    }})
    .catch(err=>{console.log(err)});

            //this.$store.dispatch('addDevice', d)
            
            //this.dialog=false
            //this.$emit('close')
            }
        },
        onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.deviceForm.reset()
            this.dialog=false
            this.$emit('close')
        }

    }
}
</script>
<style scoped>
.setng{
  border:3px solid;
  border-radius: 20px;
 
}

/* .v-application .headline{
  color:white;
}
.v-sheet.v-card {
   background-color: #263238;
} */


</style>
<template>
  <div class="text-center">
    <v-btn
    :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined
      @click="dialog = !dialog"
    >
    {{$store.getters.getTextMap().create_derived_param}}  <v-icon
      right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small
      >mdi-code-array
      </v-icon>
    </v-btn>
    <v-bottom-sheet v-model="dialog">
      <v-sheet
        class="text-center"
        
      >
      <CreateDerivedParam :device="device" v-on:close="dialog=false"/>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import CreateDerivedParam from '@/components/crud_components/CreateDerivedParam'
export default {
    name:'CreateComputedParameterBottomSheet',
    props:['device'],
    components:{
      CreateDerivedParam
    },
    data(){
        return {
            dialog:false
        }
    },
    methods:{
        
    }
}
</script>
<style>
.devc{
  border-radius:20px;
}

.v-bottom-sheet.v-dialog{
transform: scale(0.75);
margin-bottom: 65px;
width:150%;
 
}

</style>
<template>
<div>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
    <v-card>
        <v-form
    ref="writeForm"
    v-model="valid" >
        <v-card-title class="headline">
        {{ $store.getters.getTextMap().set_value}}
        </v-card-title>
        <v-card-text>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
    <v-menu
        v-if="!loading"
        ref="menufrom"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="form.time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.time"
            :label="$store.getters.getTextMap().time_current_time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu"
          v-model="form.time"
          full-width
          format="24hr"
          @click:minute="$refs.menufrom.save(form.time)"
        ></v-time-picker>
      </v-menu>
                      <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
            <v-text-field
            v-if="!loading"
      v-model="form.data"
      :counter="15"
      :rules="floatRules"
      :label="$store.getters.getTextMap().value"
      required
    ></v-text-field>
                    </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="dialog = false"
          >
          {{  $store.getters.getTextMap().cancel}}
          </v-btn>
          <v-btn
            :color="$store.getters.getColorPalette().submitbtnColor"
            text
            @click="write"
          >
           {{  $store.getters.getTextMap().write}}
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
<v-btn
      color="blue"
      @contextmenu="show"
      @click="dialog = !dialog"
   :dark="$store.getters.getColorPalette().isDark"
      
    >
    <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
     <span v-if="!loading"> {{manualParameter.name ?manualParameter.name : manualParameter.param_id}}: {{manualParameter.data}}</span>{{manualParameter.unit}}
    </v-btn>
 <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      @click="dialog=true"
    >
      <v-list>
        <v-list-item @click="dialog = !dialog">
            <v-list-item-title><strong>{{ $store.getters.getTextMap().Set}}</strong></v-list-item-title>
            <v-list-item-icon>
            <v-icon small>mdi-pen</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item @click="deleteManualParam">
            <v-list-item-title><strong>{{ $store.getters.getTextMap().delete}}</strong></v-list-item-title>
            <v-list-item-icon>
            <DeleteConfirmation v-on:confirm="deleteManualParam()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
               <v-icon small>mdi-trash-can</v-icon>
            </DeleteConfirmation>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
</div>
    
</template>

<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
import moment from 'moment'
export default {
    name:'ManualParameter',
    props:['manualParameter'],
    components:{
        InfoAlert,
        DeleteConfirmation
    },
    data: () => ({
      showMenu: false,
      valid:false,
      dialog:false,
      loading:false,
      menu:false,
      info:'',
      x:0,
      y:0,
      showDismissibleAlert:false,
      form:{
          data:null
      },
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>=0)|| 'It should be a positive number',
          v => (v && v.length <= 15 && v.length > 0) || 'Name must be less than 16 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>=0 )|| 'It should be more than zero',
          v => (v && v.length <= 15 && v.length >= 0) || 'It must be less than 16 digits',
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
    }),
    methods: {
      show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      deleteManualParam(){
          //console.log("delete")
          axios.post(this.$store.state.api+'deleteManualParameter',this.manualParameter,{headers: {
              Authorization: 'Bearer '+this.$store.state.jwt
          }}).then(response=>{
              if(response.data.status==='success'){
        this.$store.dispatch('refreshManualParameters')
        
        //this.onReset()
            //this.dialog=false
            //this.$emit('close')
            this.loading=false
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }

          }).catch(err=>{
              this.loading=false
      this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
          })
      },
      write(){
          this.$refs.writeForm.validate()
            if(this.valid){
                this.loading=true
                let payload={
                  param_id:this.manualParameter.param_id,
                  data:Number(this.form.data)*Number(this.manualParameter.correction_factor)
                }
                if(this.form.time){
                  payload['timestamp']=Number(moment(this.form.time,'HH:mm').format("X"))
                }else{
                  payload['timestamp']=Number(moment().format("X"))
                }
                axios.post(this.$store.state.api+'writeManualParameterData',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
        this.$store.dispatch('refreshManualParameters')
        
        this.onReset()
            //this.dialog=false
            //this.$emit('close')
    }else{
      //console.log(response.data.msg)
      this.info = response.data.msg;
      //this.info = d;
            this.showDismissibleAlert = true;
      //console.log(response.data.msg)
      this.loading=false
    }})
    .catch(err=>{
      this.loading=false
      this.info = err;
      //this.info = d;
            this.showDismissibleAlert = true;
      console.log(err)
      });
      
            }
      },
      onReset(){
            this.$refs.writeForm.reset()
            this.dialog=false
            this.loading=false
            this.$emit('close')
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },

}
</script>
<template>
   <v-container fluid >
       <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
       <v-row >
           <v-col v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
               <strong>Topic:</strong><span :color="$store.getters.getColorPalette().accentCode" > {{$store.getters.getTextMap().mqtt_params}}/{{device.topic}}</span>
           </v-col>
         <v-col align="right"  v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess'])">
            <span><EditMQTTBottomModal :device="device" /> </span>
              <DeleteConfirmation v-on:confirm="deleteDevice()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">  
                <v-btn class="ml-1" :color="$store.getters.getColorPalette().accentCode" small text outlined>{{$store.getters.getTextMap().delete_device}}
                <v-icon
                v-if="!loading"
                right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().deletebtnColor" small
      >
        mdi-delete
      </v-icon>
      </v-btn>
      </DeleteConfirmation>
    
                <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular>
           </v-col>
           </v-row>     
       <v-row v-if="$store.state.user  && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) "  >
           <v-col cols="12" sm="3" md="3" lg="3" xl="3" align="center">
               <CreateMQTTParamBottomSheet :device="device" />
           </v-col >
           
           
       </v-row>
       
        <v-row  v-if="parameters && parameters.length>0">
        <v-col cols="12" sm="6" md="3" lg="3" xl="2"  v-bind:key="parameter.parameter" v-for="parameter in parameters">
            <MQTTParameter v-on:deleteParameter="deleteParameter(parameter)" :parameter="parameter"/>
        </v-col>
        </v-row>
    </v-container>
</template>
<script>
import MQTTParameter from '@/components/items/MQTTParameter'
import CreateMQTTParamBottomSheet from '@/components/modals/CreateMQTTParamBottomSheet'
import EditMQTTBottomModal from '@/components/modals/EditMQTTBottomModal'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import axios from 'axios'
export default {
    name:'MQTTDevice',
    props:['device'],
    components:{
        MQTTParameter,
        CreateMQTTParamBottomSheet,
        InfoAlert,
        EditMQTTBottomModal,
        DeleteConfirmation

    },
    mounted(){
        this.form=Object({},this.device)
    },
    data(){
        return {
            showDialog:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            editDialog:false,
            valid:false,
            form:{},
           
        }
    },
    computed:{
        parameters(){
            let params= this.$store.getters.getMQTTParamsByDeviceID(this.device.device_id)
            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                return params
            }else{
                let p=[]
                for(let i of params){
                    console.log(i.engineer_access_only)
                    if(i.engineer_access_only == null || !i.engineer_access_only){
                        p.push(i)
                    }
                }
                return p
            }

        }
    },
    methods: {
       
        deleteParameter(parameter){
            this.$emit('deleteParameter',parameter)
        },
        deleteDevice(){
            //console.log('delete Device called')
            this.$emit('deleteMQTTDevice',this.device)

            if(this.$store.getters.getAccessByLevels(['engineeraccess'])){
                this.loading=true
                axios.post(this.$store.state.api+"deleteMQTTDevice",this.device,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt }}).then(response=>{
          if(response.data.status=='success'){
              this.loading=false
              this.$store.dispatch("refreshMQTTDevices")
               this.info="The Device has been removed. Please wait for the system to sync."
               this.showDismissibleAlert=true

          }else{
              this.loading=false
              this.showDismissibleAlert=true
               this.info=response.data.msg
          }
          this.loading=false
      }).catch(err=>{
          this.loading=false
          this.showDismissibleAlert=true
               this.info=err
      })

            }else{
                this.showDismissibleAlert=true
               this.info='You do not have access for deleting the workspace'
               this.loading=false
            }
            
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>